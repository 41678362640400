import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { createRefetchContainer, graphql } from 'react-relay';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PaginatedTable from '../common/PaginatedTable';
import { commonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import BaseGridContainer from '../common/BaseGridContainer';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import { renderStatus } from '../common/broker/utils';


class BrokerCashTransferList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      showProcessed: false,
    };

    this.msgRouter = props.msgRouter;
    this.transferListTable = React.createRef();
    this.columns = [
      TableFieldMeta.localId,
      TableFieldMeta.priceField('amount', 'Amount', 'amount', 'currency'),
      {
        accessor: node => node,
        Header: 'Processed',
        id: 'status',
        Cell: renderStatus,
        ...TableFieldMeta.centerAlignStyle,
      },
      TableFieldMeta.createdAt,
      {
        accessor: 'description',
        Header: 'Description',
        id: 'description',
      },
    ];
  }

  componentDidMount = () => {
    this.msgRouter.subscribe(this);
  };

  componentWillUnmount = () => {
    this.msgRouter.unsubscribe(this);
  };

  getDataNode = () => {
    const { viewer } = this.props;
    return viewer ? viewer.ibCashTransactions : null;
  };

  getFetchParams = () => {
    const { search, showProcessed } = this.state;
    return {
      search,
      processedAt_Isnull: showProcessed ? null : true,
    };
  };

  handleRowSelect = (dataRow, isSelected, newSelectedRowKey) => {
    this.msgRouter.publishState(this, {
      cashTransactionSelectedRow: isSelected ? dataRow : null,
    });
  };

  onRowSelectInfo = dataRow => (
    {
      selectEnabled: true,
      rowKey: dataRow.value.id,
      callback: this.handleRowSelect,
    }
  );

  handleCustomSearch = (e) => {
    this.setState({ search: e.target.value },
      () => { this.transferListTable.current.refreshData(); });
  };

  render() {
    const { classes, viewer, relay } = this.props;
    const { columns } = this;
    const { showProcessed } = this.state;
    if (viewer === null) {
      return (<React.Fragment />);
    } 

    return (
      <BaseGridContainer>
        <Grid item xs={12}>
          <FormGroup row>
            <TextField
              id="standard-helperText"
              label="Custom Search"
              defaultValue=""
              className={classes.formControl}
              helperText="Description, Amount"
              margin="normal"
              onChange={this.handleCustomSearch}
              style={{ marginRight: '30px' }}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={showProcessed}
                  color="primary"
                  onChange={(e) => {
                    this.setState({ showProcessed: e.target.checked },
                      () => {
                        this.transferListTable.current.refreshData();
                      });
                  }}
                />
              )}
              label="Processed"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <PaginatedTable
            ref={this.transferListTable}
            columns={columns}
            viewer={viewer}
            getDataNode={this.getDataNode}
            getFetchParams={this.getFetchParams}
            relay={relay}
            onRowSelectInfo={this.onRowSelectInfo}
          />
        </Grid>
      </BaseGridContainer>
    );
  }
}

const styledBrokerTransferList = withStyles(commonStyles)(BrokerCashTransferList);

export const brokerTransferQuery = graphql`
  query BrokerCashTransferListQuery (
    $first: Int,
    $offset: Int,
    $search: String,
    $processedAt_Isnull: Boolean,
  ) {
    viewer {
      ...BrokerCashTransferList_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search
        processedAt_Isnull: $processedAt_Isnull
      )
    }
  }
`;

const reFetchedBrokerTransferList = createRefetchContainer(
  styledBrokerTransferList,
  {
    viewer: graphql`
      fragment BrokerCashTransferList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        search: {type: String}
        processedAt_Isnull: {type: Boolean, defaultValue: null}
      ) {
        ibCashTransactions (
          first: $first
          offset: $offset
          orderBy: "-id"
          transType: "Deposits/Withdrawals"
          search: $search
          processedAt_Isnull: $processedAt_Isnull,
        ){
          edges {
            node {
              id
              createdAt
              updatedAt
              amount
              currency
              description
              brokeractivityPtr {
                id
                brokeractivitytransactionSet {
                  edges {
                    node {
                      id
                      createdAt
                    }
                  }
                }
              }
            }
          }
          count
        }
      }
    `,
  },
  brokerTransferQuery,
);

export default createQueryRendererProgress(
  brokerTransferQuery,
  reFetchedBrokerTransferList,
  (viewer, { msgRouter }) => ({ msgRouter }),
  { processedAt_Isnull: true },
);
