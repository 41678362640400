import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PaginatedTable from '../common/PaginatedTable';
import TableFieldMeta from '../../common/TableFieldMeta';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import PositionTransferProcessForm from './PositionTransferProcessForm';
import TableStatus from '../common/TableStatus';
import TableButton from '../common/buttons/TableButton';
import BrokerActivityTableFieldMeta from '../broker/BrokerActivityTableFieldMeta';
import { useCommonStyles } from '../common/Styles';
import RelayFilter from '../../common/RelayFilter';

const showProcessedDefault = false;
const showProcessedToParam = showProcessed => !showProcessed;

function BrokerPositionTransferList(props) {
  const {
    relay,
    viewer,
    msgRouter,
    showProcessed: showProcessedIn,
  } = props;
  const [editTransferRequest, setEditTransferRequest] = React.useState(null);
  const [showProcessed, setShowProcessed] = React.useState(showProcessedIn);
  const [relayFilter] = React.useState(new RelayFilter({
    search: '',
    processedAt_Isnull: showProcessedToParam(showProcessed),
  }, ['search']));

  const classes = useCommonStyles();
  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.field('direction', 'Direction', { align: 'center' }),
    // TableFieldMeta.priceField('positionAmount', 'Position Amount', 'positionAmount', 'currency'),
    TableFieldMeta.field('symbol', 'Symbol'),
    BrokerActivityTableFieldMeta.currency,
    TableFieldMeta.quantityField('quantity', 'Quantity'),
    {
      accessor: node => node,
      Header: 'Processed',
      id: 'status',
      Cell: (row) => {
        const transfer = row.value;
        let nodeAccess = transfer.brokeractivityPtr;
        nodeAccess = nodeAccess && nodeAccess.brokeractivitytransactionSet;
        
        const isProcessed = nodeAccess && nodeAccess.edges.length > 0;
        if (isProcessed) {
          return <TableStatus key={`${transfer.id}-${transfer.updatedAt}`} primary>Processed</TableStatus>;
        }

        if (transfer.ignored) {
          return <TableStatus key={`${transfer.id}-${transfer.updatedAt}`}>Ignored</TableStatus>;
        }

        if (transfer.direction === 'IN') {
          return (
            <TableButton
              color="secondary"
              onClick={() => setEditTransferRequest(transfer)}
            >
              Process
            </TableButton>
          );
        }

        return null;
      },
      ...TableFieldMeta.centerAlignStyle,
    },
  ];

  const handleRowSelect = (dataRow, isSelected, newSelectedRowKey) => {
    msgRouter.publishState(this, {
      selectedBrokerTransfer: isSelected ? dataRow : null,
    });
  };

  const onRowSelectInfo = dataRow => (
    {
      selectEnabled: true,
      rowKey: dataRow.value.id,
      callback: handleRowSelect,
    }
  );

  return (
    <div style={{ marginTop: '15pt' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormGroup row>
            <TextField
              id="standard-helperText"
              label="Custom Search"
              defaultValue=""
              className={classes.formControl}
              helperText="Description, Amount"
              margin="normal"
              onChange={e => relayFilter.updateVar('search', e.target.value)}
              style={{ marginRight: '30px' }}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={showProcessed}
                  color="primary"
                  onChange={(e) => {
                    setShowProcessed(e.target.checked);
                    relayFilter.updateVar('processedAt_Isnull', showProcessedToParam(e.target.checked));
                  }}
                />
              )}
              label="Processed"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <PaginatedTable
            getDataNode={() => (viewer ? viewer.ibTransfers : null)}
            columns={columns}
            relay={relay}
            viewer={viewer}
            onRowSelectInfo={onRowSelectInfo}
            ref={relayFilter.tableRef}
            getFetchParams={relayFilter.fetchParams}
          />
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={editTransferRequest}
        onClose={() => setEditTransferRequest(null)}
      >
        <PositionTransferProcessForm
          brokerTransfer={editTransferRequest}
          onClose={() => setEditTransferRequest(null)}
        />
      </Drawer>
    </div>
  );
}


const query = graphql`
  query BrokerPositionTransferListQuery (
    $first: Int
    $offset: Int
    $processedAt_Isnull: Boolean
    $search: String
  ) {
    viewer {
      ...BrokerPositionTransferList_viewer
      @arguments (
        first: $first
        offset: $offset
        processedAt_Isnull: $processedAt_Isnull
        search: $search
      ) 
    }
  }
`;

const container = createRefetchContainer(
  BrokerPositionTransferList,
  {
    viewer: graphql`
      fragment BrokerPositionTransferList_viewer on Query
      @argumentDefinitions (
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 10 }
        processedAt_Isnull: {type: Boolean, defaultValue: null}
        search: {type: String, defaultValue: null}
      ) {
        ibTransfers(
          first: $first
          offset: $offset
          processedAt_Isnull: $processedAt_Isnull
          search: $search
        ){
          edges {
            node {
              processedBy {
                id
                fullName
              }
              
              processedAt
              id
              direction
              positionAmount
              description
              symbol
              quantity
              contraAccountId
              contraAccountName
              contraCompany
              ibConId
              updatedAt
              createdAt
              reportDate
              cashQuantity
              currency
              ignored
              
              brokeractivityPtr {
                id
                brokeractivitytransactionSet {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);


export default createQueryRendererProgress(
  query,
  container,
  (viewer, { msgRouter }) => ({ msgRouter, showProcessed: showProcessedDefault }),
  { processedAt_Isnull: showProcessedToParam(showProcessedDefault) },
);
