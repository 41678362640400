import { graphql } from 'react-relay';

const createWithdrawalRequestsMutation = graphql`
  mutation CreateWithdrawalRequestsMutation(
    $entries: [CashWithdrawalRequestInput]!
    $batchName: String
  ) {
    createWithdrawalRequests(
      entries: $entries,
      batchName: $batchName
    ) {
      cashTransferRequests {
        id
        amount
        currency
        status
        blocked
        account {
          id
        }
      }
    }
  }
`;

export default createWithdrawalRequestsMutation;
