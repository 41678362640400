import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import InputAdornment from '@material-ui/core/InputAdornment';
import './AddressField.css';
import ManualAddressField from './ManualAddressField';

const { google } = window;

const centerOfAustralia = {
  lat: -25.2744,
  lng: 133.7751,
};

const TYPES_ALLOWED = new Set([
  'subpremise',
  'street_number',
  'route',
  'locality',
  'administrative_area_level_1',
  'country',
  'postal_code',
]);

const PICK_FIELD = {
  country: 'long_name',
};

const pick = (value, type) => value[PICK_FIELD[type] || 'short_name'];

const reducer = (accumulator, current) => {
  const type = current.types[0];
  if (TYPES_ALLOWED.has(type)) {
    accumulator[type] = pick(current, type);
  }
  return accumulator;
};

const simplify = addressComponents => (
  addressComponents
  && addressComponents.reduce
  && addressComponents.reduce(reducer, {})
);

export const getDisplayAddress = (addressServerJson) => {
  let json = {};
  try {
    json = JSON.parse(addressServerJson);
  } catch (err) {
    json = null;
  }

  if (!json) {
    return '';
  }

  /* eslint-disable camelcase */
  const {
    subpremise = '',
    street_number = '',
    route = '',
    locality = '',
    administrative_area_level_1 = '',
    country = '',
    postal_code = '',
  } = json;

  return (
    `${subpremise && `${subpremise}/`}${street_number && `${street_number} `}${route
    && `${route}, `}${locality && `${locality} `}${administrative_area_level_1
    && `${administrative_area_level_1}, `}${country && `${country} `}${postal_code}`
  );
  /* eslint-enable camelcase */
};

function AddressField(props) {
  const {
    id,
    onChange,
    title,
    fieldName,
    value,
    manualAddress,
    setManualAddress,
    setShowManualAddress,
    showManualAddress,
    isApplicant = false,
    hasValidationError = false,
    setHasValidationError,
    ...otherProps
  } = props;

  const [address, setAddress] = React.useState(value);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [validationStatus, setValidationStatus] = React.useState('');
  const autocompleteRef = React.useRef();
  const autocompleteServiceRef = React.useRef();
  const displayAddress = getDisplayAddress(value);

  const validateAddress = () => {
    setHasChanges(true);
    const { input } = document.getElementById(id);
    if (!google || !input) {
      setValidationStatus('');
    }

    autocompleteServiceRef.current.getPlacePredictions(
      { input },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
          setValidationStatus('error');
        } else if (status === google.maps.places.PlacesServiceStatus.OK) {
          setValidationStatus('validating');
        }
      },
    );
  };

  useEffect(() => {
    if (!google) {
      return;
    }

    const inputElement = document.getElementById(id);
    const autocomplete = new google.maps.places.Autocomplete(inputElement, {
      types: ['geocode'],
    });
    autocomplete.setFields(['address_component']);

    const fillInAddress = () => {
      const { address_components: addressComponents } = autocompleteRef.current.getPlace();
      const result = JSON.stringify({
        ...simplify(addressComponents || []),
        googlemaps_supported: true,
      });
      setAddress(result);
      setValidationStatus('success');
    };

    const circle = new google.maps.Circle({
      center: centerOfAustralia,
      radius: 2500000,
    });
    const service = new google.maps.places.AutocompleteService();
    autocomplete.addListener('place_changed', fillInAddress);
    autocomplete.setBounds(circle.getBounds());
    autocompleteRef.current = autocomplete;
    autocompleteServiceRef.current = service;
  }, []);

  useEffect(() => {
    if (onChange) onChange(address, validationStatus);
  }, [address, validationStatus]);

  useEffect(() => {
    if (hasValidationError) {
      const parsedAddress = JSON.parse(address);
      let updateValue = '';
      if (parsedAddress.googlemaps_supported) {
        updateValue = id === 'billing-address' ? { 'billing-address': false } : { 'mailing-address': false };
      } else if (!parsedAddress.googlemaps_supported && showManualAddress) {
        updateValue = id === 'billing-address' ? { 'billing-address': false } : { 'mailing-address': false };
      } else {
        updateValue = id === 'billing-address' ? { 'billing-address': true } : { 'mailing-address': true };
      }

      setHasValidationError(validation => ({
        ...validation,
        ...updateValue,
      }));
    }
  }, [address, showManualAddress]);

  return (
    <div>
      <TextField
        error={hasValidationError && hasValidationError[id]}
        helperText={hasValidationError[id] && (
          <div>
            Invalid address. Please select one of the suggested addresses or click
            {' '}
            <ZoomOutMapIcon style={{ fontSize: 15, verticalAlign: 'text-bottom' }} />
            {' '}
            to fill in manually.
          </div>
        )}
        id={id}
        margin="dense"
        defaultValue={displayAddress}
        onChange={validateAddress}
        onBlur={(e) => {
          if (hasChanges) {
            setAddress(JSON.stringify({
              address: e.target.value,
              googlemaps_supported: false,
            }));
            setHasChanges(false);
          }
        }}
        InputProps={{
          endAdornment: isApplicant ? <InputAdornment position="end"><ZoomOutMapIcon onClick={() => setShowManualAddress(!showManualAddress)} /></InputAdornment> : '',
        }}
        disabled={showManualAddress}
        {...otherProps}
      />
      {isApplicant && (
        <div style={{ color: 'gray' }}>
          Couldn&apos;t find your address? Click
          {' '}
          <ZoomOutMapIcon style={{ fontSize: 15, verticalAlign: 'text-bottom' }} />
          {' '}
          to fill in manually
        </div>
      )}
      {showManualAddress
        && (
          <ManualAddressField
            defaultValue={JSON.parse(value)}
            manualAddress={manualAddress}
            setManualAddress={setManualAddress}
          />
        )
      }
    </div>
  );
}

export default AddressField;
