import { withTabItems } from '../common/RoutableTabContainer';
import UserDetails from './UserDetails';
import { toGlobalId } from '../../helpers/ID';
import UserActionLog from './ActionLogList';


const userCopy = props => ({
  userId: toGlobalId('UserNode', props.match.params.userId),
  permissionEdges: props.permissionEdges,
});

const forUserCopy = props => ({
  forUserId: toGlobalId('UserNode', props.match.params.userId),
  permissionEdges: props.permissionEdges,
});

export default withTabItems([
  {
    label: 'Details',
    url: 'details',
    comp: UserDetails,
    propCopyFn: userCopy,
  },
  {
    label: 'Action Logs',
    url: 'action-logs',
    comp: UserActionLog,
    propCopyFn: forUserCopy,
  },
]);
