/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type CashTransferStatus = "APR" | "CAN" | "PEN" | "REC" | "VER" | "%future added value";
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type CashTransferRequestList_viewer$ref: FragmentReference;
declare export opaque type CashTransferRequestList_viewer$fragmentType: CashTransferRequestList_viewer$ref;
export type CashTransferRequestList_viewer = {|
  +cashTransferRequests: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +batch: ?{|
          +id: string,
          +name: string,
        |},
        +currency: Currency,
        +amount: number,
        +createdAt: any,
        +updatedAt: any,
        +status: ?CashTransferStatus,
        +blocked: boolean,
        +bankAccount: ?{|
          +accountNumber: string,
          +bsbNumber: string,
          +holderName: string,
        |},
        +account: {|
          +id: string,
          +name: ?string,
          +clientaccount: ?{|
            +referenceNumber: string
          |},
        |},
        +cashtransferrequestapprovalSet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +approvedAt: any,
              +approvedBy: {|
                +fullName: ?string
              |},
            |}
          |}>
        |},
        +cashTransfer: ?{|
          +id: string,
          +brokerActivity: {|
            +brokeractivitytransactionSet: {|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +createdAt: any,
                |}
              |}>
            |},
            +id: string,
          |},
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: CashTransferRequestList_viewer$ref,
|};
export type CashTransferRequestList_viewer$data = CashTransferRequestList_viewer;
export type CashTransferRequestList_viewer$key = {
  +$data?: CashTransferRequestList_viewer$data,
  +$fragmentRefs: CashTransferRequestList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CashTransferRequestList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "accountId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "statusIn",
      "type": "[String]",
      "defaultValue": []
    },
    {
      "kind": "LocalArgument",
      "name": "approved",
      "type": "Float",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "accountType",
      "type": "String",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "cashTransferRequests",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "account",
          "variableName": "accountId"
        },
        {
          "kind": "Variable",
          "name": "accountType",
          "variableName": "accountType"
        },
        {
          "kind": "Variable",
          "name": "approved",
          "variableName": "approved"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-createdAt"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "statusIn",
          "variableName": "statusIn"
        }
      ],
      "concreteType": "CashTransferRequestNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CashTransferRequestNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CashTransferRequestNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "batch",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CashTransferRequestBatchNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "currency",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "amount",
                  "args": null,
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "updatedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "blocked",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "bankAccount",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BankAccountNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "accountNumber",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "bsbNumber",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "holderName",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "account",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "AccountNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "clientaccount",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ClientAccountNode",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "referenceNumber",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "cashtransferrequestapprovalSet",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CashTransferRequestApprovalNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CashTransferRequestApprovalNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CashTransferRequestApprovalNode",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "approvedAt",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "approvedBy",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "UserNode",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "fullName",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "cashTransfer",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CashTransferNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "brokerActivity",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "BrokerActivityNode",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "brokeractivitytransactionSet",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "BrokerActivityTransactionNodeConnection",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "edges",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "BrokerActivityTransactionNodeEdge",
                              "plural": true,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "node",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "BrokerActivityTransactionNode",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v2/*: any*/)
                                  ]
                                }
                              ]
                            }
                          ]
                        },
                        (v0/*: any*/)
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dcbc1d3766dadd27cf2d9acbedd8bea8';
module.exports = node;
