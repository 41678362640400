import { graphql } from 'react-relay';

const updateUserCredentials = graphql`
  mutation UpdateUserCredentialsMutation (
   $userId: ID!
   $username: String
   $password: String
   $email: String
   $firstName: String
   $lastName: String
  ) {
    updateUserCredentials(
      userId: $userId
      username: $username
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
    ) {
      user {
        dateJoined
        email
        username
        firstName
        lastName
      }
    }
  }
`;

export default updateUserCredentials;
