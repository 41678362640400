import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import { InfoOutlined } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { useCommonStyles } from '../common/Styles';
import TableButton from '../common/buttons/TableButton';
import ConfirmationDlg from '../common/dialogs/ConfirmationDlg';
import TextFieldChangeModalDlgForm from '../common/dialogs/TextFieldChangeModalDlgForm';
import updateUserCredentials from '../../mutations/UpdateUserCredentials';
import updateUserStatus from '../../mutations/UpdateUserStatus';
import { doCommitPromise } from '../../common/commit';
import DateTime from '../common/DateTimeMoment';
import { VerticalTableWidget } from '../common/VerticalTableWidget';
import { readPermitted } from '../../common/helpers';
import UserSecurityQuestionsTable from './UserSecurityQuestionsTable';
import TableFieldMeta, { Gid } from '../../common/TableFieldMeta';
import DataTableWidget from '../common/DataTableWidget';
import PhoneFieldChangeModalDlgForm from '../common/dialogs/PhoneFieldChangeModalDlgForm';
import convertClientToAdviser from '../../mutations/ConvertClientToAdviser';
import UserSubscriptionsDetails from './UserSubscriptionsDetails';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import AdminPermissionDrawer from './AdminPermissionDrawer';
import AddressModifyTableButtonDrawer from './modifyDrawer/AddressModifyTableButtonDrawer';
import createMFADeactivationRequestMutation from '../../mutations/CreateMFADeactivationRequestMutation';
import notiStack from '../../common/notiStack';


function UserDetails(props) {
  const {
    viewer,
    relay,
    filter,
    permissionEdges,
  } = props;
  if (!viewer.authUsers.edges) return null;
  const user = viewer.authUsers.edges[0].node;
  filter.setRelay(relay);

  const adviserReadable = user.isStaff && readPermitted(permissionEdges, 'SUP');
  const classes = useCommonStyles();
  const [showChangePassword, setShowChangePassword] = React.useState(false);
  const [showChangeUsername, setShowChangeUsername] = React.useState(false);
  const [showChangeFirstname, setShowChangeFirstname] = React.useState(false);
  const [showChangeLastname, setShowChangeLastname] = React.useState(false);
  const [showChangeEmail, setShowChangeEmail] = React.useState(false);
  const [showActivateUserAccount, setShowActivateUserAccount] = React.useState(false);
  const [showDeactivateUserAccount, setShowDeactivateUserAccount] = React.useState(false);
  const [showDeactivateMfa, setShowDeactivateMfa] = React.useState(false);
  const [showVerifyEmail, setShowVerifyEmail] = React.useState(false);
  const [showChangeMobileNumber, setShowChangeMobileNumber] = React.useState(false);
  const [showAdviserConversionForm, setShowAdviserConversionForm] = React.useState(false);

  const userModelDetailsMeta = [
    ['Mobile No', node => (
      <Grid container spacing={1} justify="flex-end">
        <Grid item style={{ display: 'flex' }}>
          {node.mobileNumber}
          {user.usermodel && !user.usermodel.parsedMobileNumber.isValidNumber && (
            <Tooltip
              placement="top"
              title="Mobile Number is invalid"
              style={{ marginLeft: '4px' }}
            >
              <InfoOutlined
                color="error"
                fontSize="small"
              />
            </Tooltip>
          )}
        </Grid>
        <Grid item>
          <TableButton
            color="primary"
            onClick={() => setShowChangeMobileNumber(true)}
          >
            Change
          </TableButton>
        </Grid>
      </Grid>
    )],
    ['Billing Address', node => (
      <AddressModifyTableButtonDrawer
        userModel={node}
        addressType="billingAddress"
        title="Change Billing Address"
        fieldName="Billing Address"
      />
    ),
    ],
    ['Mailing Address', node => (
      <AddressModifyTableButtonDrawer
        userModel={node}
        addressType="mailingAddress"
        title="Change Mailing Address"
        fieldName="Mailing Address"
      />
    ),
    ],
    ['', () => (
      <TableButton
        color="primary"
        onClick={() => setShowAdviserConversionForm(true)}
      >
        Convert to Adviser
      </TableButton>
    )],
  ];

  const userDetailsMeta = [
    ['First Name', node => (
      <Grid container spacing={1} justify="flex-end">
        <Grid item>
          {node.firstName}
        </Grid>
        <Grid item>
          <TableButton
            color="primary"
            onClick={() => setShowChangeFirstname(true)}
          >
            Change
          </TableButton>
        </Grid>
      </Grid>
    )],
    ['Last Name', node => (
      <Grid container spacing={1} justify="flex-end">
        <Grid item>
          {node.lastName}
        </Grid>
        <Grid item>
          <TableButton
            color="primary"
            onClick={() => setShowChangeLastname(true)}
          >
            Change
          </TableButton>
        </Grid>
      </Grid>
    )],
    ['Email', node => (
      <Grid container spacing={1} justify="flex-end">
        <Grid item>
          {node.email}
        </Grid>
        {!node.emailVerified
          && (
            <Grid item>
              <TableButton
                color="primary"
                onClick={() => setShowVerifyEmail(true)}
              >
                Verify
              </TableButton>
            </Grid>
          )
        }
        <Grid item>
          <TableButton
            color="primary"
            onClick={() => setShowChangeEmail(true)}
          >
            Change
          </TableButton>
        </Grid>
      </Grid>
    )],
    ['Username', node => (
      <Grid container spacing={1} justify="flex-end">
        <Grid item>
          {node.username}
        </Grid>
        <Grid item>
          <TableButton
            color="primary"
            onClick={() => setShowChangeUsername(true)}
          >
            Change
          </TableButton>
        </Grid>
      </Grid>
    )],
    ['Status', node => (
      <Grid container spacing={1} justify="flex-end">
        <Grid item>
          {node.isActive ? 'Active' : 'Inactive'}
        </Grid>
        <Grid item>
          {node.isActive
            ? (
              <TableButton
                color="secondary"
                onClick={() => setShowDeactivateUserAccount(true)}
              >
                Deactivate
              </TableButton>
            )
            : (
              <TableButton
                color="primary"
                onClick={() => setShowActivateUserAccount(true)}
              >
                Activate
              </TableButton>
            )
          }
        </Grid>
      </Grid>
    )],
    ['Date Joined', node => <DateTime>{node.dateJoined}</DateTime>],
    ['Gid', node => <Gid gid={node.id} />],
    ['', node => (
      <Grid container spacing={1} justify="flex-end">
        <Grid item>
          <TableButton
            color="primary"
            onClick={() => setShowChangePassword(true)}
          >
            Change password
          </TableButton>
        </Grid>
        {node.mfaConfigured && (
          <Grid item>
            <TableButton
              color="secondary"
              onClick={() => setShowDeactivateMfa(true)}
            >
              Deactivate MFA
            </TableButton>
          </Grid>
        )}
      </Grid>
    )],
  ];

  if (adviserReadable) {
    userDetailsMeta.push(
      ['Admin Permissions', node => <AdminPermissionDrawer user={node} />],
    );
  }

  return (
    <div className={classes.innerTabRoot}>
      <Dialog
        key={user.dateJoined}
        open={showChangePassword}
        onClose={() => setShowChangePassword(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialogSingleInput}
        maxWidth="sm"
        fullWidth
      >
        <TextFieldChangeModalDlgForm
          fieldName="Password"
          title="Change password"
          onChange={newValue => doCommitPromise(
            updateUserCredentials,
            { userId: user.id, password: newValue },
            () => setShowChangePassword(false),
          )}
          onClose={() => setShowChangePassword(false)}
        />
      </Dialog>

      <Dialog
        key={user.dateJoined}
        open={showChangeFirstname}
        onClose={() => setShowChangeFirstname(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialogSingleInput}
        maxWidth="sm"
        fullWidth
      >
        <TextFieldChangeModalDlgForm
          fieldName="First Name"
          title="Change First Name"
          onChange={newValue => doCommitPromise(
            updateUserCredentials,
            { userId: user.id, firstName: newValue },
            () => setShowChangeFirstname(false),
          )}
          onClose={() => setShowChangeFirstname(false)}
        />
      </Dialog>

      <Dialog
        key={user.dateJoined}
        open={showChangeLastname}
        onClose={() => setShowChangeLastname(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialogSingleInput}
        maxWidth="sm"
        fullWidth
      >
        <TextFieldChangeModalDlgForm
          fieldName="Last Name"
          title="Change Last Name"
          onChange={newValue => doCommitPromise(
            updateUserCredentials,
            { userId: user.id, lastName: newValue },
            () => setShowChangeLastname(false),
          )}
          onClose={() => setShowChangeLastname(false)}
        />
      </Dialog>

      <Dialog
        key={user.dateJoined}
        open={showChangeUsername}
        onClose={() => setShowChangeUsername(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialogSingleInput}
        maxWidth="sm"
        fullWidth
      >
        <TextFieldChangeModalDlgForm
          fieldName="Username"
          title="Change Username"
          onChange={newValue => doCommitPromise(
            updateUserCredentials,
            { userId: user.id, username: newValue },
            () => setShowChangeUsername(false),
          )}
          onClose={() => setShowChangeUsername(false)}
        />
      </Dialog>

      <Dialog
        key={user.dateJoined}
        open={showChangeEmail}
        onClose={() => setShowChangeEmail(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialogSingleInput}
        maxWidth="sm"
        fullWidth
      >
        <TextFieldChangeModalDlgForm
          fieldName="Email"
          title="Change email"
          onChange={newEMail => doCommitPromise(
            updateUserCredentials,
            { userId: user.id, email: newEMail },
            () => setShowChangeEmail(false),
          )}
          onClose={() => setShowChangeEmail(false)}
        />
      </Dialog>

      <Dialog
        open={showActivateUserAccount}
        onClose={() => setShowActivateUserAccount(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialogSingleInput}
        maxWidth="sm"
        fullWidth
      >
        <ConfirmationDlg
          message="Are you sure you want to activate this account?"
          options={[{ id: 0, caption: 'No' }, { id: 1, caption: 'Yes' }]}
          onOption={(option) => {
            if (option === 1) {
              return doCommitPromise(
                updateUserStatus,
                { userId: user.id, isActive: !user.isActive },
                () => setShowActivateUserAccount(false),
              );
            }
            setShowActivateUserAccount(false);
            return null;
          }}
        />
      </Dialog>

      <Dialog
        open={showDeactivateUserAccount}
        onClose={() => setShowDeactivateUserAccount(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialogSingleInput}
        maxWidth="sm"
        fullWidth
      >
        <ConfirmationDlg
          message="Are you sure you want to deactivate this account?"
          options={[{ id: 0, caption: 'No' }, { id: 1, caption: 'Yes' }]}
          onOption={(option) => {
            if (option === 1) {
              return doCommitPromise(
                updateUserStatus,
                { userId: user.id, isActive: !user.isActive },
                () => setShowDeactivateUserAccount(false),
              );
            }
            setShowDeactivateUserAccount(false);
            return null;
          }}
        />
      </Dialog>

      <Dialog
        open={showVerifyEmail}
        onClose={() => setShowVerifyEmail(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialogSingleInput}
        maxWidth="sm"
        fullWidth
      >
        <ConfirmationDlg
          message="Are you sure you want to mark the email address as verified?"
          options={[{ id: 0, caption: 'No' }, { id: 1, caption: 'Yes' }]}
          onOption={(option) => {
            if (option === 1) {
              return doCommitPromise(
                updateUserStatus,
                { userId: user.id, verifyEmail: true },
                () => setShowVerifyEmail(false),
              );
            } 
            setShowVerifyEmail(false);
            return null;
          }}
        />
      </Dialog>

      <Dialog
        open={showChangeMobileNumber}
        onClose={() => setShowChangeMobileNumber(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialogSingleInput}
        maxWidth="sm"
        fullWidth
      >
        <PhoneFieldChangeModalDlgForm
          fieldName="Mobile Number"
          title="Change mobile number"
          onClose={() => setShowChangeMobileNumber(false)}
          userId={user.id}
          parseMobileNumber={user.usermodel && user.usermodel.parsedMobileNumber}
        />
      </Dialog>

      <Dialog
        open={showAdviserConversionForm}
        onClose={() => setShowAdviserConversionForm(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialogSingleInput}
        maxWidth="sm"
        fullWidth
      >
        <ConfirmationDlg
          message="Are you sure you want to convert this user into an adviser? This operation is not reversible."
          options={[{ id: 0, caption: 'No' }, { id: 1, caption: 'Yes' }]}
          onOption={(option) => {
            if (option === 1) {
              return doCommitPromise(
                convertClientToAdviser,
                { userModelId: user.usermodel.id, tenantId: null },
                (response, errors) => {
                  setShowAdviserConversionForm(false);
                  if (!errors) {
                    window.location.reload();
                  }
                },
              );
            }
            setShowAdviserConversionForm(false);
            return null;
          }}
        />
      </Dialog>

      <Dialog
        open={showDeactivateMfa}
        onClose={() => setShowDeactivateMfa(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialogSingleInput}
        maxWidth="sm"
        fullWidth
      >
        <ConfirmationDlg
          message="Are you sure you want to deactivate MFA for this account?"
          options={[{ id: 0, caption: 'No' }, { id: 1, caption: 'Yes' }]}
          onOption={(option) => {
            if (option === 1) {
              return doCommitPromise(
                createMFADeactivationRequestMutation,
                { userId: user.id },
                () => {
                  setShowDeactivateMfa(false);
                  notiStack.success('MFA deactivation request created');
                },
              );
            }
            setShowDeactivateMfa(false);
            return null;
          }}
        />
      </Dialog>

      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          lg={5}
        >
          <Grid container spacing={3}>
            <>
              <VerticalTableWidget
                columnMeta={userDetailsMeta}
                dataNode={user}
                title="User Details"
                xs={12}
              />
              {user.usermodel && (
                <DataTableWidget
                  xs={12}
                  title="Accounts"
                  showHeadings={false}
                  columnMeta={[
                    TableFieldMeta.account(
                      node => node,
                      'Account',
                      { charLimit: 60, showNumber: true },
                    ),
                  ]}
                  dataNodes={user.usermodel.accounts.edges.map(x => x.node)}
                />
              )}

            </>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          lg={7}
        >
          <Grid container spacing={3}>
            {user.usermodel && (
              <VerticalTableWidget
                columnMeta={userModelDetailsMeta}
                dataNode={user.usermodel}
                title="Client Details"
                xs={12}
              />
            )}
            <UserSecurityQuestionsTable
              securityQuestions={user.securityQuestions}
            />

            <UserSubscriptionsDetails
              // key={user.usersubscriptionSet.edges.count}
              subscriptionSet={user.usersubscriptionSet.edges.map(x => x.node)}
              user={user}
              onAdd={() => {
                filter.update();
              }}
              showAdd
            />

          </Grid>

        </Grid>


      </Grid>

    </div>
  );
}

const query = graphql`
  query UserDetailsQuery(
    $userId: ID!,
  ) {
    viewer{
      authUsers(id: $userId) {
        edges {
          node {
            ...UserDetails_fetchedUser
            @relay(mask: false)
          }
        }
      }
    }
  }
`;

const container = createRefetchContainer(
  UserDetails,
  {
    fetchedUser: graphql`
      fragment UserDetails_fetchedUser on UserNode {
        dateJoined
        firstName
        lastName
        lastLogin
        lastName
        email
        username
        userType
        id
        isActive
        isStaff
        emailVerified
        mfaConfigured
        usermodel {
          id
          mobileNumber
          billingAddress
          mailingAddress
          parsedMobileNumber {
            countrySymbol
            countryCode
            nationalNumber
            isValidNumber
          }
          accounts {
            edges {
              node {
                id
                name
              }
            }
          }
          userPtr {
            id
          }
        }
        adviser {
          id
          userPtr {
            fullName
          }
          mobileNumber
          parentTenant {
            id
            name
          }
        }
        securityQuestions {
          edges {
            node {
              id
              question
              answer
            }
          }
        }

        usersubscriptionSet {
          edges {
            node {
              ...UserSubscriptionsDetails_all
              @relay(mask: false)
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ userId }) => ({ userId }),
);
