import React from 'react';
import Grid from '@material-ui/core/Grid';
import { graphql } from 'react-relay';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemBottomButtonContainer
  from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { UpdateButton } from '../common/buttons/BaseButton';
import { doCommitPromise } from '../../common/commit';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import { isRecurNull } from '../../common/helpers';
import AdminPermissions from './AdminPermissions';
import setAdminPermission from '../../mutations/SetAdminPermission';

export function AdminPermissionDrawerContainer(props) {
  const classes = useCommonStyles();
  const { children } = props;
  return (
    <div className={classes.drawerRoot}>
      {children}
    </div>
  );
}

function AdminPermissionForm(props) {
  const {
    onClose,
    user,
  } = props;
  const [members] = React.useState({
    permissions: user.adminpermissionSet.edges.map(x => ({
      verb: x.node.verb,
      resource: x.node.resource,
    })),
  });
  // Need a copy of read-only objects

  return (
    <>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Modify Admin Permission"
        />

        <Grid item xs={12}>
          <AdminPermissions
            permissions={members.permissions}
            onSetPermissions={(n) => {
              members.permissions = n;
            }}
          />
        </Grid>

        <DrawerGridItemBottomButtonContainer>
          <Grid item>

            <UpdateButton
              onClickPromise={() => doCommitPromise(
                setAdminPermission,
                {
                  userId: user.id,
                  permissions: members.permissions,
                },
                () => (onClose && onClose()),
                () => null,
              )}
            />

          </Grid>
        </DrawerGridItemBottomButtonContainer>
      </DrawerGridItemContainer>
    </>
  );
}

const query = graphql`
  query AdminPermissionFormQuery (
    $userId: ID!
  ) {
    viewer {
      authUsers(
        id: $userId,
      ) {
        edges {
          node {
            id
            adminpermissionSet {
              edges {
                node {
                  id
                  verb
                  resource
                  createdAt
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default createQueryRendererProgressV2(
  query,
  AdminPermissionForm,
  (outerProps, props) => ({
    user: isRecurNull(props, ['viewer', 'authUsers', 'edges', 0, 'node']),
  }),
  ({ user }) => ({ userId: user.id }),
);
