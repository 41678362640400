/**
 * @flow
 * @relayHash 5d8800b47fbab6808956a2dca9840bcc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateUserCredentialsMutationVariables = {|
  userId: string,
  username?: ?string,
  password?: ?string,
  email?: ?string,
  firstName?: ?string,
  lastName?: ?string,
|};
export type UpdateUserCredentialsMutationResponse = {|
  +updateUserCredentials: ?{|
    +user: ?{|
      +dateJoined: any,
      +email: string,
      +username: string,
      +firstName: string,
      +lastName: string,
    |}
  |}
|};
export type UpdateUserCredentialsMutation = {|
  variables: UpdateUserCredentialsMutationVariables,
  response: UpdateUserCredentialsMutationResponse,
|};
*/


/*
mutation UpdateUserCredentialsMutation(
  $userId: ID!
  $username: String
  $password: String
  $email: String
  $firstName: String
  $lastName: String
) {
  updateUserCredentials(userId: $userId, username: $username, email: $email, password: $password, firstName: $firstName, lastName: $lastName) {
    user {
      dateJoined
      email
      username
      firstName
      lastName
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "username",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "password",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "email",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "firstName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "lastName",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "email",
    "variableName": "email"
  },
  {
    "kind": "Variable",
    "name": "firstName",
    "variableName": "firstName"
  },
  {
    "kind": "Variable",
    "name": "lastName",
    "variableName": "lastName"
  },
  {
    "kind": "Variable",
    "name": "password",
    "variableName": "password"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  },
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dateJoined",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateUserCredentialsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateUserCredentials",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserCredentials",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateUserCredentialsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateUserCredentials",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserCredentials",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateUserCredentialsMutation",
    "id": null,
    "text": "mutation UpdateUserCredentialsMutation(\n  $userId: ID!\n  $username: String\n  $password: String\n  $email: String\n  $firstName: String\n  $lastName: String\n) {\n  updateUserCredentials(userId: $userId, username: $username, email: $email, password: $password, firstName: $firstName, lastName: $lastName) {\n    user {\n      dateJoined\n      email\n      username\n      firstName\n      lastName\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ec3de4164fa085ea4e31d104f1ed0eb1';
module.exports = node;
