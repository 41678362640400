import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import BaseButton from '../buttons/BaseButton';

function TextFieldChangeModalDlgForm(props) {
  const {
    onChange,
    onClose,
    title,
    fieldName,
    fieldType,
    value,
  } = props;
  const [ucVar] = React.useState({
    fieldValue: value,
  });
  return (
    <React.Fragment>
      <DialogContent>
        <DialogContentText>
          {title}
        </DialogContentText>
        <TextField
          autoFocus
          defaultValue={value}
          margin="dense"
          id="name"
          label={fieldName}
          type={fieldType}
          fullWidth
          onChange={(e) => {
            // if (onChange) onChange(e.target.value);
            ucVar.fieldValue = e.target.value;
          }}
        />
      </DialogContent>
      <DialogActions>
        <BaseButton
          onClick={() => (onClose && onClose())}
          color="primary"
          buttonText="cancel"
        />
        <BaseButton
          onClickPromise={() => (onChange && onChange(ucVar.fieldValue))}
          color="primary"
          buttonText="change"
        />
      </DialogActions>

    </React.Fragment>
  );
}

TextFieldChangeModalDlgForm.propTypes = {
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  fieldName: PropTypes.string,
  fieldType: PropTypes.string,
};

TextFieldChangeModalDlgForm.defaultProps = {
  onChange: null,
  onClose: null,
  title: '',
  fieldName: '',
  fieldType: 'text',
};

export default TextFieldChangeModalDlgForm;
