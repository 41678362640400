import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import {
  FormControl, InputAdornment, InputLabel, MenuItem, Select, Tooltip, 
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import BaseButton from '../buttons/BaseButton';

import { useCommonStyles } from '../Styles';
import './PhoneFieldChangeModalDlgForm.css';
import withMetaLoaded from '../meta/LazyMetaLoader';
import { MetaDefs } from '../meta/MetaDef';
import { doCommitPromise, graceErrChecked } from '../../../common/commit';
import updateUserMobileNumber from '../../../mutations/UpdateUserMobileNumber';


function PhoneFieldChangeModalDlgForm(props) {
  const {
    onClose,
    title,
    fieldName,
    parseMobileNumber,
    metaData,
    userId,
  } = props;

  const {
    countrySymbol,
    nationalNumber,
    countryCode,
    isValidNumber,
  } = parseMobileNumber;

  const phoneCountries = (metaData && metaData[MetaDefs.COUNTRY_CODES]) || [];
  const [subscriberNumber, setSubscriberNumber] = React.useState(nationalNumber);

  const [phoneCountryCode, setPhoneCountryCode] = React.useState(
    getPhoneCountryCode(phoneCountries, countrySymbol, countryCode),
  );
  const phonePrefixNumber = React.useMemo(() => {
    const found = phoneCountries.find(item => item.code === phoneCountryCode);

    if (found) {
      return found.phoneNumberPrefix;
    }

    return '+61';
  }, [phoneCountryCode]);

  const classes = useCommonStyles();

  return (
    <React.Fragment>
      <DialogContent>
        <DialogContentText>
          {title}
        </DialogContentText>
        <div style={{ display: 'flex' }}>
          <FormControl required className={classes.formControlHalf}>
            <InputLabel id="phoneCountry" required={false}>Country</InputLabel>
            <Select 
              labelId="phoneCountry" 
              value={phoneCountryCode} 
              onChange={event => setPhoneCountryCode(event.target.value)}
            >
              {phoneCountries.map((option, idx) => {
                const { code } = option;
                return <MenuItem key={code} value={code}>{code}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            error={!isValidNumber}
            className="phone-input"
            defaultValue={nationalNumber}
            value={subscriberNumber}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{phonePrefixNumber}</InputAdornment>
              ),
              endAdornment: !isValidNumber && (
                <Tooltip title="Mobile Number is invalid" placement="top">
                  <InfoOutlined fontSize="small" color="error" />
                </Tooltip>
              ),
            }}
            id="name"
            label={fieldName}
            style={{ 
              marginBottom: '8px',
              marginTop: '8px',
              marginLeft: '10px',
              flex: 1,              
            }}
            onChange={(e) => {
              setSubscriberNumber(e.target.value.replace(/\D/g, ''));
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <BaseButton
          onClick={() => (onClose && onClose())}
          color="primary"
          buttonText="cancel"
        />
        <BaseButton
          onClickPromise={() => doCommitPromise(
            updateUserMobileNumber,
            {
              userId,
              mobileNumber: `${phonePrefixNumber}${subscriberNumber}`,
            },
            graceErrChecked(onClose),
          )}
          color="primary"
          buttonText="change"
        />
      </DialogActions>

    </React.Fragment>
  );
}

const getPhoneCountryCode = (phoneCountries, phoneCountry, phonePrefix) => {
  const foundCountry = phoneCountries.find(item => item.code === phoneCountry);

  if (!foundCountry) {
    const prefix = phonePrefix.includes('+') ? phonePrefix : `+${phonePrefix}`;
    const foundPrefix = phoneCountries.find(item => item.phoneNumberPrefix === prefix);

    return foundPrefix ? foundPrefix.code : 'AU';
  }

  return foundCountry.code;
};

PhoneFieldChangeModalDlgForm.propTypes = {
  onClose: PropTypes.func,
  fieldName: PropTypes.string,
  title: PropTypes.string,
  parseMobileNumber: PropTypes.object,
};

PhoneFieldChangeModalDlgForm.defaultProps = {
  onClose: null,
  title: '',
  fieldName: '',
  parseMobileNumber: {},
};

export default withMetaLoaded(PhoneFieldChangeModalDlgForm, MetaDefs.COUNTRY_CODES);
