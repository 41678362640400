import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import ReactTable from 'react-table';
import * as PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import { ROWS_PER_PAGE } from '../../common/constants';
import TablePaginationActions from './pagination/TablePaginationActions';
import TableFieldSelectorDlgForm from './table/TableFieldSelectorDlgForm';
import MUITableRenderer from './table/MUITableRenderer';


const injectedTdStyles = {
  paddingTop: '4px',
  paddingBottom: '4px',
};

// eslint-disable-next-line no-unused-vars
export function MuiPaginator(props) {
  const {
    count,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
  } = props;
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <TablePagination
        rowsPerPageOptions={TablePaginationActions.ROW_COUNT_OPTIONS}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          native: true,
        }}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}

function TablePaginator(props) {
  const {
    tableRender,
    columns,
    getDataNode,
    nodeResolver,
    filter,
    onRowSelectInfo,
    title,
  } = props;
  const data = [];
  const [settingsDlgOpen, setSettingsDlgOpen] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const rowsPerPage = filter.params.first;
  const page = Math.floor(filter.params.offset / filter.params.first);
  const TableRender = tableRender;
  let tableData = data || [];
  const dataNode = getDataNode();
  const { count } = dataNode;
  if (dataNode) {
    tableData = dataNode.edges.map(nodeResolver);
  }

  filter.setPreFetchListener(() => setIsLoading(true));
  filter.setPostFetchListener(() => setIsLoading(false));

  const displayColumns = [];
  columns.forEach((item, index) => {
    displayColumns.push({
      ...item,
      style: { ...item.style, ...injectedTdStyles },
    });
  });

  return (
    <>
      <Dialog
        open={settingsDlgOpen}
        onClose={() => setSettingsDlgOpen(false)}
        aria-labelledby="table-settings-dialog-title"
        aria-describedby="table-settings-dialog-description"
      >
        <TableFieldSelectorDlgForm
          onClose={() => setSettingsDlgOpen(false)}
          columns={columns}
          onChangeColumnOrder={newDisplayColumns => this.setState(
            { displayColumns: newDisplayColumns },
          )}
        />
      </Dialog>
      <TableRender
        columns={displayColumns}
        data={tableData}
        rowsPerPage={rowsPerPage}
        count={count}
        page={page}
        fetchSeq={0}
        title={title}
        onChangeColumnOrder={() => {
          setSettingsDlgOpen(true);
        }}
        onOpenColumnSettings={() => setSettingsDlgOpen(true)}
        onChangePage={(pageNo) => {
          const newOffset = pageNo * rowsPerPage;
          filter.updateVar('offset', newOffset);
        }}
        onChangeRowsPerPage={(newRowsPerPage) => {
          filter.updateVar('first', Number(newRowsPerPage));
        }}
        onRowSelectInfo={onRowSelectInfo}
        onFetchData={(state, instance) => {
          const newOffset = state.page * rowsPerPage;
          filter.updateVars({
            offset: newOffset,
            first: state.pageSize,
          });
        }}
        isLoading={isLoading}
      />
    </>
  );
}

TablePaginator.propTypes = {
  tableRender: PropTypes.object,
  getDataNode: PropTypes.func.isRequired,
  getFetchParams: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowSelectInfo: PropTypes.func,
  rowsPerPage: PropTypes.number,
  nodeResolver: PropTypes.func,
  title: PropTypes.string,
  filter: PropTypes.object,
};

TablePaginator.defaultProps = {
  tableRender: MUITableRenderer,
  getFetchParams: () => {},
  rowsPerPage: ROWS_PER_PAGE,
  nodeResolver: edge => edge.node,
  filter: null,
  ...ReactTable.defaultProps,
};

export default TablePaginator;
