import * as React from 'react';
import { commitMutation, createRefetchContainer, graphql } from 'react-relay';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Decimal } from 'decimal.js';
import Drawer from '@material-ui/core/Drawer';
import PaginatedTable from '../common/PaginatedTable';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import TransferApprovalCell from '../common/TransferApprovalCell';
import { fromGlobalId, toLocal } from '../../helpers/ID';
import BaseButton, {
  AddButton,
  ReconcileButton,
  UploadButton,
} from '../common/buttons/BaseButton';
import environment from '../../Environment';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import AddDepositForm from './AddDepositForm';
import AddWithdrawalForm from './AddWithdrawalForm';
import reconcileCashTransferRequests from '../../mutations/ReconcileCashTransferRequests';
import BulkAddWithdrawalForm from './BulkAddWithdrawalForm';
import TableIconButton from '../common/table/TableIconButton';
import { doCommitPromise } from '../../common/commit';
import Aba from '../../icons/Aba';
import generateABAForCTRBatch from '../../mutations/GenerateABAForCTRBatch';
import downloadFileContent from '../../common/download';

const statusDef = [
  ['VER', 'Verified', true],
  ['CAN', 'Cancelled', false],
  ['APR', 'Approved', false],
  ['REC', 'Reconciled', false],
];

const initialFilterStatus = {
  showStatus: statusDef.map(x => x[2]),
  filterApproved: true,
  approved: 0,
  search: '',
};

const isApprovedOnceApplicable = (showStatus) => {
  let applicable = true;
  showStatus.forEach((item, index) => {
    if (item) {
      if (statusDef[index][0] === 'VER') {
        applicable = applicable && item;
      } else {
        applicable = applicable && !item;
      }
    }
  });
  return applicable;
};

const filterStatusToVars = (ucVar) => {
  // const defaultStatusFilter = ['PEN', 'VER']; //, 'CAN', 'APR', 'VER'
  const statusIn = [];
  ucVar.showStatus.forEach((item, index) => {
    if (item) {
      statusIn.push(statusDef[index][0]);
    }
  });
  let approved = null;
  if (isApprovedOnceApplicable(ucVar.showStatus)) {
    approved = ucVar.filterApproved ? ucVar.approved : null;
  }

  return {
    search: ucVar.search,
    approved,
    statusIn,
  };
};

function CashTransferRequestList(props) {
  const [cashTransactionSelectedRow, setCashTransactionSelectedRow] = React.useState(null);
  const [selectionSet, setSelectionSet] = React.useState([]);
  const [showStatus, setShowStatus] = React.useState(initialFilterStatus.showStatus);
  const [filterApproved, setFilterApproved] = React.useState(initialFilterStatus.filterApproved);
  const [approved, setApproved] = React.useState(initialFilterStatus.approved);
  const [showDepositDrawer, setShowDepositDrawer] = React.useState(false);
  const [showWithdrawDrawer, setShowWithdrawDrawer] = React.useState(false);
  const [showBulkWithdrawDrawer, setShowBulkWithdrawDrawer] = React.useState(false);
  const [tableRef] = React.useState(React.createRef());
  const [ucVar] = React.useState({
    refreshRequestSeq: 0,
    ...initialFilterStatus,
  });

  const {
    accountId,
    msgRouter,
    viewer,
    relay,
  } = props;

  const classes = useCommonStyles();
  const accountColumns = accountId ? [] : [
    TableFieldMeta.account(node => node.account),
    {
      accessor: (node) => {
        if (node.account.clientaccount) {
          return node.account.clientaccount.referenceNumber;
        }
        return '';
      },
      id: 'referenceNumber',
      Header: 'Reference Number',
    },
  ];

  const renderTransferApprovalCell = (row) => {
    const transferRequest = row.value;
    return (
      <TransferApprovalCell
        key={transferRequest.updatedAt}
        transferRequest={transferRequest}
        ibCashTransfer={cashTransactionSelectedRow}
      />
    );
  };

  const columns = [
    TableFieldMeta.localId,
    ...accountColumns,
    TableFieldMeta.priceField('amount', 'Amount', 'amount', 'currency'),
    {
      accessor: (node) => {
        if (node.bankAccount) {
          const { bankAccount } = node;
          return `${bankAccount.bsbNumber}-${bankAccount.accountNumber} ${bankAccount.holderName}`;
        }
        return '';
      },
      Header: 'Bank Account',
      id: 'bankAccount',
      ...TableFieldMeta.leftAlignStyle,
    },
    {
      accessor: node => node,
      Header: '',
      id: 'link',
      ...TableFieldMeta.centerAlignStyle,
      Cell: row => renderTransferApprovalCell(row),
    },
    {
      accessor: node => node,
      Header: 'Select',
      id: 'select',
      Cell: (row) => {
        if (row.value.cashTransfer || row.value.cancelled) {
          return <React.Fragment />;
        }
        const isSelected = selectionSet.findIndex(element => element.id === row.value.id) > -1;
        const key = `${row.value.id}-${isSelected}-${selectionSet.length > 0 ? selectionSet[0].currency : '___'}`;
        return (
          <Checkbox
            style={{ padding: '0px' }}
            checked={isSelected}
            key={key}
            disabled={selectionSet.length > 0 && selectionSet[0].currency !== row.value.currency}
            size="small"
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            onChange={(e) => {
              if (e.target.checked) {
                if (selectionSet.length === 0 || selectionSet[0].currency === row.value.currency) {
                  const newSelectionSet = [...selectionSet, row.value];
                  setSelectionSet(newSelectionSet);
                }
              } else {
                const indexIn = selectionSet.findIndex(element => element.id === row.value.id);
                if (indexIn > -1) {
                  selectionSet.splice(indexIn, 1);
                  setSelectionSet([...selectionSet]);
                }
              }
            }}
          />
        );
      },
    },
    TableFieldMeta.fieldCell(
      'batch',
      'Batch',
      row => (row.value.batch && (
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item>
            {toLocal(row.value.batch.id)}
          </Grid>
          {/*<TableCompactGridItem>*/}
          <Grid item>
            <TableIconButton
              key={`${row.value.id}-resend-email`}
              iconClass={Aba}
              tooltip={`Export ABA: ${row.value.batch.name}`}
              color="primary"
              onClickPromise={() => doCommitPromise(
                generateABAForCTRBatch,
                {
                  cashTransferRequestBatchId: row.value.batch.id,
                },
                (response) => {
                  downloadFileContent(response.generateAbaForCtrBatch.abaText,
                    'text/plain',
                    `${row.value.batch.name}.aba`);
                },
                () => null,
              )}
            />
          </Grid>
          {/*</TableCompactGridItem>*/}
        </Grid>
      )),
    ),
    {
      accessor: node => (node.cashTransfer ? fromGlobalId(node.cashTransfer.brokerActivity.id)[1] : ''),
      Header: 'Cash Transaction',
      id: 'brokerActivity',
      ...TableFieldMeta.rightAlignStyle,
    },
    TransferApprovalCell.updatedAtMeta,
  ];

  const onPublishState = (client, state) => {
    const {
      cashTransactionSelectedRow: selection,
      refreshRequestSeq,
    } = state;
    setCashTransactionSelectedRow(selection);

    if (refreshRequestSeq !== ucVar.refreshRequestSeq) {
      tableRef.current.refreshData();
      ucVar.refreshRequestSeq = refreshRequestSeq;
    }
  };

  msgRouter.addStateListener(onPublishState);

  const reconcileTransfers = () => {
    const variables = {
      transferRequestIds: selectionSet.map(element => element.id),
      brokerTransferId: cashTransactionSelectedRow.id,
    };

    commitMutation(
      environment,
      {
        mutation: reconcileCashTransferRequests,
        variables,
        onCompleted: (response, errors) => {
          if (errors) return;
          setSelectionSet([]);
        },
      },
    );
  };

  const transferSum = selectionSet.reduce(
    (accumulator, currentValue) => accumulator.plus(
      new Decimal(currentValue.amount),
    ),
    new Decimal(0),
  );

  let transfersMatching = cashTransactionSelectedRow
    && (new Decimal(cashTransactionSelectedRow.amount)).equals(transferSum);
  transfersMatching = selectionSet.reduce(
    (v, transfer) => v
      && (transfer.amount < 0 ? transfer.status === 'VER' : (
        transfer.status === 'VER' || transfer.status === 'APR'
      )),
    transfersMatching,
  );

  React.useEffect(() => function cleanup() {
    msgRouter.removeStateListener(onPublishState);
  });

  if (viewer === null) return <React.Fragment />;
  return (
    <div className={classes.innerTabRoot}>
      <Grid container spacing={2}>
        <Grid item xs={12}>

          <Drawer
            anchor="right"
            open={showDepositDrawer}
            onClose={() => setShowDepositDrawer(false)}
          >
            <AddDepositForm
              onAdd={() => tableRef.current.refreshData()}
              onClose={() => setShowDepositDrawer(false)}
            />
          </Drawer>

          <Drawer
            anchor="right"
            open={showWithdrawDrawer}
            onClose={() => setShowWithdrawDrawer(false)}
          >
            <AddWithdrawalForm
              onAdd={() => tableRef.current.refreshData()}
              onClose={() => setShowWithdrawDrawer(false)}
            />
          </Drawer>

          <Drawer
            anchor="right"
            open={showBulkWithdrawDrawer}
            onClose={() => setShowBulkWithdrawDrawer(false)}
          >
            <BulkAddWithdrawalForm
              onAdd={() => tableRef.current.refreshData()}
              onClose={() => setShowBulkWithdrawDrawer(false)}
            />
          </Drawer>

          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <TextField
                id="standard-helperText"
                label="Custom Search"
                defaultValue=""
                fullWidth
                helperText="Account name, Amount, ID"
                margin="normal"
                onChange={(e) => {
                  ucVar.search = e.target.value;
                  tableRef.current.refreshData();
                }}
                style={{ marginRight: '30px' }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              {
                statusDef.map((item, index) => (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        size="small"
                        checked={ucVar.showStatus[index]}
                        value="allowExtraEmails"
                        color="primary"
                        onChange={(e) => {
                          ucVar.showStatus[index] = e.target.checked;
                          setShowStatus([...ucVar.showStatus]);
                          tableRef.current.refreshData();
                        }}
                      />
                    )}
                    label={item[1]}
                  />
                ))
              }
            </Grid>
            <Grid item xs={12} lg={2}>
              <FormControlLabel
                control={(
                  <Checkbox
                    size="small"
                    checked={filterApproved}
                    value="allowExtraEmails"
                    color="primary"
                    onChange={(e) => {
                      ucVar.filterApproved = e.target.checked;
                      setFilterApproved(ucVar.filterApproved);
                      tableRef.current.refreshData();
                    }}
                  />
                )}
                disabled={!isApprovedOnceApplicable(showStatus)}
                label="Filter Approvals"
              />
              <TextField
                id="standard-helperText"
                label="Approval Count"
                defaultValue={approved}
                fullWidth
                helperText=""
                margin="normal"
                type="number"
                onChange={(e) => {
                  ucVar.approved = Number(e.target.value);
                  setApproved(ucVar.approved);
                  tableRef.current.refreshData();
                }}
                disabled={!isApprovedOnceApplicable(showStatus) || !filterApproved}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item>
                  <AddButton
                    buttonText="deposit"
                    onClick={() => setShowDepositDrawer(true)}
                  />
                </Grid>
                <Grid item>
                  <AddButton
                    buttonText="withdraw"
                    onClick={() => setShowWithdrawDrawer(true)}
                  />
                </Grid>
                { !accountId && (
                  <Grid item>
                    <UploadButton
                      buttonText="Upload"
                      onClick={() => setShowBulkWithdrawDrawer(true)}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>


        </Grid>

        {/*Multiple Selection Approval Row*/}
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
            <Grid item>
              <Typography variant="subtitle1">
                {`Selected: ${selectionSet.length}, Amount: ${transferSum.toString()}`}
              </Typography>
            </Grid>
            <Grid item>
              <ReconcileButton
                disabled={!transfersMatching}
                buttonText="Reconcile"
                onClick={() => reconcileTransfers()}
              />
            </Grid>
            <Grid item>
              <BaseButton
                disabled={selectionSet.length === 0}
                buttonText="Clear"
                onClick={() => setSelectionSet([])}
              />
            </Grid>
          </Grid>
        </Grid>


        <Grid sm={12} md={12}>
          <PaginatedTable
            ref={tableRef}
            columns={columns}
            viewer={viewer}
            getDataNode={() => (viewer ? viewer.cashTransferRequests : null)}
            getFetchParams={() => ({
              accountId,
              ...filterStatusToVars(ucVar),
            })}
            relay={relay}
          />
        </Grid>

      </Grid>
    </div>
  );
}

export const query = graphql`
  query CashTransferRequestListQuery (
    $first: Int
    $offset: Int
    $search: String
    $accountId: ID
    $statusIn: [String]
    $approved: Float
    $accountType: String
  ) {
    viewer {
      ...CashTransferRequestList_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search
        accountId: $accountId
        statusIn: $statusIn
        approved: $approved
        accountType: $accountType
      )
    }
  }
`;

const reFetched = createRefetchContainer(
  CashTransferRequestList,
  {
    viewer: graphql`
      fragment CashTransferRequestList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        search: {type: String}
        accountId: {type: ID, defaultValue: null }
        statusIn: {type: "[String]", defaultValue: [] }
        approved: {type: Float}
        accountType: {type: String}
      ) {
        cashTransferRequests(
          first: $first
          offset: $offset
          search: $search
          orderBy: "-createdAt"
          account: $accountId
          statusIn: $statusIn
          approved: $approved
          accountType: $accountType
        ) {
          edges {
            node {
              id
              batch {
                id
                name
              }
              currency
              amount
              createdAt
              updatedAt
              status
              blocked
              currency
              bankAccount {
                accountNumber
                bsbNumber
                holderName
              }
              account {
                id
                name
                clientaccount {
                  referenceNumber
                }
              }
              
              cashtransferrequestapprovalSet {
                edges {
                  node {
                    approvedAt
                    approvedBy {
                      fullName
                    }
                  }
                }
              }
              
              cashTransfer {
                id
                brokerActivity {
                  brokeractivitytransactionSet {
                    edges {
                      node {
                        id
                        createdAt
                      }
                    }
                  }
                  id
                }
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default createQueryRendererProgress(
  query,
  reFetched,
  (viewer, { msgRouter, accountId, accountType }) => ({ msgRouter, accountId, accountType }),
  ({ accountId, accountType }) => ({
    accountId,
    accountType,
    ...filterStatusToVars(initialFilterStatus),
  }),
);
