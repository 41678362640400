/**
 * @flow
 * @relayHash ce49f6cb0799f385abdacfb59c5edca2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AccountList_viewer$ref = any;
export type AccountStatus = "ACCOUNT_APPROVED" | "ACCOUNT_CLOSED" | "ACCOUNT_NOT_INITIATED" | "ACCOUNT_PENDING" | "ACCOUNT_SUBMITTED" | "%future added value";
export type AccountListContainerQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  accountType?: ?string,
  search?: ?string,
  tenantId?: ?string,
  adviserId?: ?string,
  statusIn?: ?$ReadOnlyArray<?AccountStatus>,
|};
export type AccountListContainerQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: AccountList_viewer$ref
  |}
|};
export type AccountListContainerQuery = {|
  variables: AccountListContainerQueryVariables,
  response: AccountListContainerQueryResponse,
|};
*/


/*
query AccountListContainerQuery(
  $first: Int
  $offset: Int
  $accountType: String
  $search: String
  $tenantId: ID
  $adviserId: ID
  $statusIn: [AccountStatus]
) {
  viewer {
    ...AccountList_viewer_2Hyk7y
    id
  }
}

fragment AccountList_viewer_2Hyk7y on Query {
  clientAccounts(first: $first, offset: $offset, accountType: $accountType, search: $search, tenant: $tenantId, adviser: $adviserId, statusInV2Typed: $statusIn, orderBy: "-createdAt") {
    edges {
      node {
        id
        name
        referenceNumber
        accountStatus
        accountType
        createdAt
        updatedAt
        userModels {
          edges {
            node {
              id
              username
            }
          }
        }
        tenant {
          id
          name
        }
        accountPtr {
          id
          actualCashBase
          availableCashBase
          stockValueBase
          actualCashBalances {
            currency
            balance
          }
          availableCashBalances {
            currency
            balance
          }
          holdings {
            portfolio {
              id
              trackingVue {
                name
                id
              }
            }
            contract {
              id
              symbol
              name
            }
            quantity
            id
          }
        }
        __typename
      }
      cursor
    }
    count
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "accountType",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "tenantId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "adviserId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "statusIn",
    "type": "[AccountStatus]",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "accountType",
  "variableName": "accountType"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v4 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v5 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "adviser",
    "variableName": "adviserId"
  },
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "-createdAt"
  },
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "statusInV2Typed",
    "variableName": "statusIn"
  },
  {
    "kind": "Variable",
    "name": "tenant",
    "variableName": "tenantId"
  }
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "currency",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "balance",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AccountListContainerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AccountList_viewer",
            "args": [
              (v1/*: any*/),
              {
                "kind": "Variable",
                "name": "adviserId",
                "variableName": "adviserId"
              },
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "Variable",
                "name": "statusIn",
                "variableName": "statusIn"
              },
              {
                "kind": "Variable",
                "name": "tenantId",
                "variableName": "tenantId"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AccountListContainerQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "clientAccounts",
            "storageKey": null,
            "args": (v5/*: any*/),
            "concreteType": "ClientAccountNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ClientAccountNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClientAccountNode",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "referenceNumber",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "accountStatus",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "accountType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "userModels",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserModelNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserModelNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserModelNode",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "username",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tenant",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TenantNode",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "accountPtr",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AccountNode",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "actualCashBase",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "availableCashBase",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "stockValueBase",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "actualCashBalances",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MultiCurrencyNode",
                            "plural": true,
                            "selections": (v8/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "availableCashBalances",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MultiCurrencyNode",
                            "plural": true,
                            "selections": (v8/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "holdings",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "HoldingBalanceNode",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "portfolio",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "PortfolioNode",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "trackingVue",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "VueNode",
                                    "plural": false,
                                    "selections": [
                                      (v7/*: any*/),
                                      (v6/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "contract",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ContractNode",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "symbol",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  (v7/*: any*/)
                                ]
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "quantity",
                                "args": null,
                                "storageKey": null
                              },
                              (v6/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "clientAccounts",
            "args": (v5/*: any*/),
            "handle": "connection",
            "key": "Accounts_clientAccounts",
            "filters": [
              "offset",
              "accountType",
              "search",
              "tenant",
              "adviser",
              "statusInV2Typed",
              "orderBy"
            ]
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AccountListContainerQuery",
    "id": null,
    "text": "query AccountListContainerQuery(\n  $first: Int\n  $offset: Int\n  $accountType: String\n  $search: String\n  $tenantId: ID\n  $adviserId: ID\n  $statusIn: [AccountStatus]\n) {\n  viewer {\n    ...AccountList_viewer_2Hyk7y\n    id\n  }\n}\n\nfragment AccountList_viewer_2Hyk7y on Query {\n  clientAccounts(first: $first, offset: $offset, accountType: $accountType, search: $search, tenant: $tenantId, adviser: $adviserId, statusInV2Typed: $statusIn, orderBy: \"-createdAt\") {\n    edges {\n      node {\n        id\n        name\n        referenceNumber\n        accountStatus\n        accountType\n        createdAt\n        updatedAt\n        userModels {\n          edges {\n            node {\n              id\n              username\n            }\n          }\n        }\n        tenant {\n          id\n          name\n        }\n        accountPtr {\n          id\n          actualCashBase\n          availableCashBase\n          stockValueBase\n          actualCashBalances {\n            currency\n            balance\n          }\n          availableCashBalances {\n            currency\n            balance\n          }\n          holdings {\n            portfolio {\n              id\n              trackingVue {\n                name\n                id\n              }\n            }\n            contract {\n              id\n              symbol\n              name\n            }\n            quantity\n            id\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    count\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7572477616280efcc061978555975d68';
module.exports = node;
