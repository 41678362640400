/**
 * @flow
 * @relayHash 52b3a08d0f8c25c6f17eb63ad55fa895
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConvertClientToAdviserMutationVariables = {|
  userModelId: string,
  tenantId?: ?string,
|};
export type ConvertClientToAdviserMutationResponse = {|
  +convertClientToAdviser: ?{|
    +adviser: ?{|
      +id: string,
      +username: string,
      +email: string,
      +parentTenant: {|
        +id: string,
        +name: string,
      |},
    |},
    +deactivatedUserModel: ?{|
      +id: string,
      +username: string,
    |},
  |}
|};
export type ConvertClientToAdviserMutation = {|
  variables: ConvertClientToAdviserMutationVariables,
  response: ConvertClientToAdviserMutationResponse,
|};
*/


/*
mutation ConvertClientToAdviserMutation(
  $userModelId: ID!
  $tenantId: ID
) {
  convertClientToAdviser(userModelId: $userModelId, tenantId: $tenantId) {
    adviser {
      id
      username
      email
      parentTenant {
        id
        name
      }
    }
    deactivatedUserModel {
      id
      username
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userModelId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "tenantId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "convertClientToAdviser",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
      },
      {
        "kind": "Variable",
        "name": "userModelId",
        "variableName": "userModelId"
      }
    ],
    "concreteType": "ConvertClientToAdviser",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "adviser",
        "storageKey": null,
        "args": null,
        "concreteType": "AdviserNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "parentTenant",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deactivatedUserModel",
        "storageKey": null,
        "args": null,
        "concreteType": "UserModelNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ConvertClientToAdviserMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ConvertClientToAdviserMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ConvertClientToAdviserMutation",
    "id": null,
    "text": "mutation ConvertClientToAdviserMutation(\n  $userModelId: ID!\n  $tenantId: ID\n) {\n  convertClientToAdviser(userModelId: $userModelId, tenantId: $tenantId) {\n    adviser {\n      id\n      username\n      email\n      parentTenant {\n        id\n        name\n      }\n    }\n    deactivatedUserModel {\n      id\n      username\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ae1a0a7a47665b60107c66ef240458d0';
module.exports = node;
