/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ReportState = "ERR" | "FIN" | "REQ" | "%future added value";
export type ReportTrigger = "EOF" | "OG" | "SG" | "UG" | "%future added value";
export type ReportType = "AAR" | "ACC" | "ACT" | "AHD" | "AIR" | "BRO" | "CGT" | "CRP" | "CTR" | "CUS" | "FUM" | "FXT" | "HOLD" | "INC" | "INV" | "OCA" | "OFE" | "ORD" | "POT" | "RMAD" | "TRD" | "TXN" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ReportList_viewer$ref: FragmentReference;
declare export opaque type ReportList_viewer$fragmentType: ReportList_viewer$ref;
export type ReportList_viewer = {|
  +reports: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +createdAt: any,
        +updatedAt: any,
        +pdfUrl: ?string,
        +xlsxUrl: ?string,
        +reportType: ReportType,
        +trigger: ReportTrigger,
        +state: ReportState,
        +pdf: ?string,
        +xlsx: ?string,
        +clientAccount: ?{|
          +id: string,
          +name: ?string,
          +accountPtr: {|
            +id: string,
            +name: ?string,
          |},
        |},
        +customreport: ?{|
          +name: ?string,
          +year: ?number,
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: ReportList_viewer$ref,
|};
export type ReportList_viewer$data = ReportList_viewer;
export type ReportList_viewer$key = {
  +$data?: ReportList_viewer$data,
  +$fragmentRefs: ReportList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ReportList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "clientAccountId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "triggerIn",
      "type": "[String]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "reportTypeIn",
      "type": "[String]",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "reports",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "clientAccountId",
          "variableName": "clientAccountId"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-createdAt"
        },
        {
          "kind": "Variable",
          "name": "reportTypeIn",
          "variableName": "reportTypeIn"
        },
        {
          "kind": "Variable",
          "name": "triggerIn",
          "variableName": "triggerIn"
        }
      ],
      "concreteType": "ReportNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ReportNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ReportNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "updatedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "pdfUrl",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "xlsxUrl",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "reportType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "trigger",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "state",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "pdf",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "xlsx",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "clientAccount",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ClientAccountNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "accountPtr",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "AccountNode",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customreport",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomReportNode",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "year",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '823bcc44a89062e9bdd6ba25c227c4fb';
module.exports = node;
