/**
 * @flow
 * @relayHash 1b3ee53982dc5fd28271f62f746e52bb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApprovableStatus = "APPROVED" | "CANCELLED" | "PENDING" | "%future added value";
export type CreateMFADeactivationRequestMutationVariables = {|
  userId: string
|};
export type CreateMFADeactivationRequestMutationResponse = {|
  +createMfaDeactivationRequest: ?{|
    +mfaDeactivationRequest: ?{|
      +id: string,
      +status: ApprovableStatus,
      +createdAt: any,
      +forUser: {|
        +id: string,
        +email: string,
      |},
      +hasCompleteApprovals: ?boolean,
    |}
  |}
|};
export type CreateMFADeactivationRequestMutation = {|
  variables: CreateMFADeactivationRequestMutationVariables,
  response: CreateMFADeactivationRequestMutationResponse,
|};
*/


/*
mutation CreateMFADeactivationRequestMutation(
  $userId: ID!
) {
  createMfaDeactivationRequest(userId: $userId) {
    mfaDeactivationRequest {
      id
      status
      createdAt
      forUser {
        id
        email
      }
      hasCompleteApprovals
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createMfaDeactivationRequest",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "CreateMFADeactivationRequest",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "mfaDeactivationRequest",
        "storageKey": null,
        "args": null,
        "concreteType": "MFADeactivationRequestNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "forUser",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasCompleteApprovals",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateMFADeactivationRequestMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateMFADeactivationRequestMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateMFADeactivationRequestMutation",
    "id": null,
    "text": "mutation CreateMFADeactivationRequestMutation(\n  $userId: ID!\n) {\n  createMfaDeactivationRequest(userId: $userId) {\n    mfaDeactivationRequest {\n      id\n      status\n      createdAt\n      forUser {\n        id\n        email\n      }\n      hasCompleteApprovals\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a86a0082cbbff84c32b87c557f10b7a0';
module.exports = node;
