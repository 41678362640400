import { graphql } from 'react-relay';

// language=GraphQL
const createMFADeactivationRequestMutation = graphql`
  mutation CreateMFADeactivationRequestMutation(
    $userId: ID!
  ) {
    createMfaDeactivationRequest (
      userId: $userId
    ) {
      mfaDeactivationRequest {
        id
        status
        createdAt
        forUser {
          id
          email
        }
        hasCompleteApprovals
      }
    }
  }
`;

export default createMFADeactivationRequestMutation;
