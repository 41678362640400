/**
 * @flow
 * @relayHash c9aef0e99898de221e3faa86cc8b5542
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdminResource = "ACC" | "ADV" | "APP" | "BAC" | "BAT" | "CHR" | "CON" | "DEV" | "FEE" | "FXO" | "FXR" | "MPR" | "ODR" | "OMT" | "PER" | "RPT" | "SUB" | "SUP" | "TEN" | "TRA" | "TRD" | "USR" | "VUE" | "%future added value";
export type Verb = "MUTATE" | "READ" | "%future added value";
export type AdminPermissionFormQueryVariables = {|
  userId: string
|};
export type AdminPermissionFormQueryResponse = {|
  +viewer: ?{|
    +authUsers: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +adminpermissionSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +verb: Verb,
                +resource: AdminResource,
                +createdAt: any,
              |}
            |}>
          |},
        |}
      |}>
    |}
  |}
|};
export type AdminPermissionFormQuery = {|
  variables: AdminPermissionFormQueryVariables,
  response: AdminPermissionFormQueryResponse,
|};
*/


/*
query AdminPermissionFormQuery(
  $userId: ID!
) {
  viewer {
    authUsers(id: $userId) {
      edges {
        node {
          id
          adminpermissionSet {
            edges {
              node {
                id
                verb
                resource
                createdAt
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "authUsers",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "userId"
    }
  ],
  "concreteType": "UserNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "UserNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "UserNode",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "adminpermissionSet",
              "storageKey": null,
              "args": null,
              "concreteType": "AdminPermissionNodeConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "AdminPermissionNodeEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "AdminPermissionNode",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "verb",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "resource",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "createdAt",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AdminPermissionFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AdminPermissionFormQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AdminPermissionFormQuery",
    "id": null,
    "text": "query AdminPermissionFormQuery(\n  $userId: ID!\n) {\n  viewer {\n    authUsers(id: $userId) {\n      edges {\n        node {\n          id\n          adminpermissionSet {\n            edges {\n              node {\n                id\n                verb\n                resource\n                createdAt\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3c38d44b5568c1ef75881de533f7ee89';
module.exports = node;
