import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  button: {
    margin: '0px',
    padding: '0px 8px',
    fontSize: '0.65rem',
  },
  buttonProgress: {
    color: green[400],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -8,
  },
}));

export default function TableButton(props) {
  const {
    buttonText,
    children,
    onClick,
    onClickPromise,
    color,
    ...otherProps
  } = props;

  const customStyle = {};
  const theme = useTheme();
  if (color === 'primary') customStyle.color = theme.palette.secondary.main;
  else if (color === 'secondary') customStyle.color = theme.palette.primary.main;
  const [isLoading, setLoading] = React.useState(false);
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button
        color={color}
        {...otherProps}
        variant="contained"
        className={classes.button}
        size="small"
        onClick={(e) => {
          if (isLoading) return;
          onClick && onClick(e);
          if (!onClickPromise) return;
          onClickPromise().finally(() => setLoading(false));
        }}
      >
        {children || buttonText}
      </Button>
      {
        isLoading && (
          <CircularProgress
            thickness={6}
            size={16}
            className={classes.buttonProgress}
            style={customStyle}
          />
        )
      }
    </div>
  );
}

TableButton.propTypes = {
  buttonText: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  onClickPromise: PropTypes.func,
  ...Button.propTypes,
};

TableButton.defaultProps = {
  buttonText: 'Add Button',
  color: 'default',
  variant: 'contained',
  onClickPromise: null,
  ...Button.defaultProps,
};
