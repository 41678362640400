/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ActionType = "AA" | "AACK" | "AAD" | "ABF" | "AC" | "ACCL" | "ACH" | "ACR" | "ACRO" | "ADIE" | "AEIE" | "AFNC" | "AFNU" | "AIAD" | "AIAE" | "AIAN" | "AIAR" | "AIAS" | "AIAT" | "AIRR" | "AIRS" | "AIRT" | "AM" | "ANC" | "AR" | "ARE" | "AS" | "ATCH" | "AU" | "BAAD" | "BACR" | "BADI" | "BAIG" | "BAPE" | "BAPI" | "BAPR" | "BAUI" | "BAUP" | "BAVE" | "BCC" | "BCU" | "BTIG" | "BTUI" | "CAREMFT" | "CASC" | "CASETFT" | "CASU" | "CIC" | "CISC" | "CISS" | "CISU" | "CIT" | "CIU" | "CIX" | "CTAPR" | "CTBLK" | "CTCAA" | "CTCAN" | "CTREC" | "CTREQ" | "CTUBL" | "CTVFY" | "GCBFV" | "GCBRA" | "GCBRC" | "GCBRD" | "GCBRF" | "GCBRI" | "GCBRV" | "GCPCO" | "GCPCR" | "GCPFL" | "GCPSU" | "MACC" | "MATC" | "MATU" | "MLTC" | "MLTU" | "OAA" | "OAR" | "OC" | "OCC" | "OCD" | "OM" | "OMCLA" | "OMCLD" | "OMCLN" | "OMCLR" | "OMCNI" | "OMD" | "OMEXP" | "OMOVA" | "OMOVD" | "OMPR" | "OMQA" | "OMRBC" | "OP" | "OPFC" | "OQ" | "OR" | "ORRCE" | "OTC" | "OX" | "SC" | "SCR" | "SF" | "SR" | "SRA" | "SRF" | "SS" | "SU" | "TBF" | "TCH" | "TCR" | "TIGC" | "TIGD" | "TIGU" | "TISC" | "TISU" | "TPC" | "TPU" | "TRSC" | "TRSU" | "ULMVF" | "ULMVS" | "ULNS" | "ULSC" | "ULUV" | "UMA" | "UMAF" | "UMAP" | "UMAR" | "UMARP" | "UMCP" | "UMCR" | "UMD" | "UMRRC" | "UMURP" | "UUE" | "UUF" | "UUL" | "UUP" | "UUSA" | "UUSD" | "UUU" | "UVE" | "VSPA" | "VSPR" | "VSPU" | "ZBTR" | "ZBTU" | "ZIR" | "ZITR" | "ZIU" | "ZPC" | "ZTP" | "ZTR" | "ZUC" | "ZUU" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ActionLogList_viewer$ref: FragmentReference;
declare export opaque type ActionLogList_viewer$fragmentType: ActionLogList_viewer$ref;
export type ActionLogList_viewer = {|
  +actionLogUserRequests: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +actionTime: any,
        +actionType: ActionType,
        +actionId: number,
        +actionDescription: string,
        +ipAddress: ?string,
        +userAgent: ?string,
        +user: ?{|
          +id: string,
          +fullName: ?string,
          +email: string,
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: ActionLogList_viewer$ref,
|};
export type ActionLogList_viewer$data = ActionLogList_viewer;
export type ActionLogList_viewer$key = {
  +$data?: ActionLogList_viewer$data,
  +$fragmentRefs: ActionLogList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ActionLogList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "userId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "forUserId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "actionTypeIn",
      "type": "[String]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "actionIdIn",
      "type": "[Int]",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "actionLogUserRequests",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "actionId_In",
          "variableName": "actionIdIn"
        },
        {
          "kind": "Variable",
          "name": "actionType_In",
          "variableName": "actionTypeIn"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "forUserId",
          "variableName": "forUserId"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-actionTime"
        },
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "userId"
        }
      ],
      "concreteType": "ActionLogUserRequestNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ActionLogUserRequestNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ActionLogUserRequestNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "actionTime",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "actionType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "actionId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "actionDescription",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "ipAddress",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "userAgent",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "user",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "fullName",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "email",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '1388f94490b2131c5b03eacd2deb8977';
module.exports = node;
