/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type InstrumentType = "CASH" | "STK" | "WAR" | "%future added value";
export type OrderStatus = "CAN" | "CRE" | "FIL" | "INI" | "INM" | "PEX" | "PFL" | "POS" | "QUE" | "REJ" | "%future added value";
export type OrderType = "LMT" | "MKT" | "SLMT" | "SMKT" | "TLMT" | "TMKT" | "%future added value";
export type Side = "BUY" | "SELL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BrokerOrderList_viewer$ref: FragmentReference;
declare export opaque type BrokerOrderList_viewer$fragmentType: BrokerOrderList_viewer$ref;
export type BrokerOrderList_viewer = {|
  +brokerOrders: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +createdAt: any,
        +orderType: OrderType,
        +matched: boolean,
        +quantity: ?number,
        +quantityFilled: ?number,
        +quantityLeft: ?number,
        +status: OrderStatus,
        +instrumentType: InstrumentType,
        +orderRef: ?string,
        +side: Side,
        +lastSyncTime: ?any,
        +twsPermId: ?number,
        +twsOrderId: ?number,
        +parentOrder: ?{|
          +id: string
        |},
        +orderSet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +stockorder: ?{|
                +contract: {|
                  +id: string,
                  +symbol: string,
                |}
              |},
              +fxorder: ?{|
                +contract: {|
                  +id: string,
                  +localSymbol: string,
                |}
              |},
            |}
          |}>
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: BrokerOrderList_viewer$ref,
|};
export type BrokerOrderList_viewer$data = BrokerOrderList_viewer;
export type BrokerOrderList_viewer$key = {
  +$data?: BrokerOrderList_viewer$data,
  +$fragmentRefs: BrokerOrderList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "BrokerOrderList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "statusIn",
      "type": "[String]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "instrumentType",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "matched",
      "type": "Boolean",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "brokerOrders",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "instrumentType",
          "variableName": "instrumentType"
        },
        {
          "kind": "Variable",
          "name": "matched",
          "variableName": "matched"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-id"
        },
        {
          "kind": "Variable",
          "name": "statusIn",
          "variableName": "statusIn"
        }
      ],
      "concreteType": "BrokerOrderNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "BrokerOrderNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "BrokerOrderNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "orderType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "matched",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "quantity",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "quantityFilled",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "quantityLeft",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "instrumentType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "orderRef",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "side",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "lastSyncTime",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "twsPermId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "twsOrderId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "parentOrder",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BrokerOrderNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "orderSet",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "OrderNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "OrderNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "OrderNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "stockorder",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "StockOrderNode",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "contract",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "ContractNode",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    {
                                      "kind": "ScalarField",
                                      "alias": null,
                                      "name": "symbol",
                                      "args": null,
                                      "storageKey": null
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "fxorder",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "FxOrderNode",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "contract",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "FxContractNode",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    {
                                      "kind": "ScalarField",
                                      "alias": null,
                                      "name": "localSymbol",
                                      "args": null,
                                      "storageKey": null
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '97aeaf7ddc641befe646fb382c643bee';
module.exports = node;
