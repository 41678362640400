import React from 'react';
import { metaDefs } from './MetaDef';
import { createQueryRendererProgressV2 } from '../QueryRendererProgressV2';

const loadedMetaDict = {};

const propConverter = (collector, metaDefCode) => (props, innerProps) => {
  const { metaData = {} } = props;
  const newMetaData = collector(innerProps);
  if (newMetaData) {
    loadedMetaDict[metaDefCode] = newMetaData;
  }
  metaData[metaDefCode] = newMetaData;
  return { metaData };
};

const withMetaLoaded = (component, metaDefCode) => (props) => {
  const { metaData = {} } = props;
  const Component = component;
  if (metaDefCode in loadedMetaDict) {
    metaData[metaDefCode] = loadedMetaDict[metaDefCode];
  }
  const metaDef = metaDefs.find(i => i.name === metaDefCode);
  if (!metaDef || (metaDefCode in loadedMetaDict)) {
    return (
      <Component
        {...props}
        metaData={metaData}
      />
    );
  }

  const QrpComp = createQueryRendererProgressV2(
    metaDef.query,
    component,
    propConverter(metaDef.collector, metaDefCode),
    {},
    { fetchReady: metaDef.fetchReady },
  );

  return <QrpComp {...props} />;
};

export default withMetaLoaded;
