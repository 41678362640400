import { graphql } from 'react-relay';

const createWithdrawalRequestMutation = graphql`
  mutation CreateWithdrawalRequestMutation (
      $accountId: ID!
      $amount: Float!
      $currency: String!
      $bankAccountBsb: String
      $bankAccountId: ID
      $bankAccountName: String
      $bankAccountNumber: String
  ) {
      createWithdrawalRequest(
          accountId: $accountId
          amount: $amount
          currency: $currency
          bankAccountBsb: $bankAccountBsb
          bankAccountId: $bankAccountId
          bankAccountName: $bankAccountName
          bankAccountNumber: $bankAccountNumber
      ) {
          cashTransferRequest {
              id
              amount
              currency
              status
              blocked
              account {
                  id
              }
          }
      }
  }
`;

export default createWithdrawalRequestMutation;
