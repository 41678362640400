/**
 * @flow
 * @relayHash 20e3e77ded7a2da18bccab8dcb3134ac
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateUserStatusMutationVariables = {|
  userId: string,
  isActive?: ?boolean,
  verifyEmail?: ?boolean,
|};
export type UpdateUserStatusMutationResponse = {|
  +updateUserStatus: ?{|
    +user: ?{|
      +emailVerified: ?boolean,
      +isActive: boolean,
    |}
  |}
|};
export type UpdateUserStatusMutation = {|
  variables: UpdateUserStatusMutationVariables,
  response: UpdateUserStatusMutationResponse,
|};
*/


/*
mutation UpdateUserStatusMutation(
  $userId: ID!
  $isActive: Boolean
  $verifyEmail: Boolean
) {
  updateUserStatus(userId: $userId, isActive: $isActive, verifyEmail: $verifyEmail) {
    user {
      emailVerified
      isActive
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isActive",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "verifyEmail",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "isActive",
    "variableName": "isActive"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  },
  {
    "kind": "Variable",
    "name": "verifyEmail",
    "variableName": "verifyEmail"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailVerified",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isActive",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateUserStatusMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateUserStatus",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserStatus",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateUserStatusMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateUserStatus",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserStatus",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateUserStatusMutation",
    "id": null,
    "text": "mutation UpdateUserStatusMutation(\n  $userId: ID!\n  $isActive: Boolean\n  $verifyEmail: Boolean\n) {\n  updateUserStatus(userId: $userId, isActive: $isActive, verifyEmail: $verifyEmail) {\n    user {\n      emailVerified\n      isActive\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '75f7a7e249fa60ea71d4103ac2da783a';
module.exports = node;
