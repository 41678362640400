import { graphql } from 'react-relay';

const updateUserAddress = graphql`
  mutation UpdateUserAddressMutation (
   $userId: ID!
   $billingAddress: String
   $mailingAddress: String
  ) {
    updateUserAddress(
      userId: $userId
      billingAddress: $billingAddress
      mailingAddress: $mailingAddress
    ) {
      user {
        usermodel {
          id
          billingAddress
          mailingAddress
        }
      }
    }
  }
`;

export default updateUserAddress;
