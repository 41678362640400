import { graphql } from 'react-relay';

export class MetaDefs {}

MetaDefs.COUNTRY_CODES = 'countryCodes';
MetaDefs.CURRENCY_SYMBOLS = 'currencySymbols';

const countryCodeQuery = graphql`
  query MetaDefCountryCodeQuery {
    countryMeta {
      code
      name
      phoneNumberPrefix
    }
  }
`;

const currencySymbolsQuery = graphql`
  query MetaDefCurrencyCodeQuery {
    currencyMeta {
      isoCode
      symbol
    }
  }
`;


export const metaDefs = [
  {
    name: MetaDefs.COUNTRY_CODES,
    query: countryCodeQuery,
    fetchReady: props => !!props.countryMeta,
    collector: props => props.countryMeta,
  },
  {
    name: MetaDefs.CURRENCY_SYMBOLS,
    query: currencySymbolsQuery,
    fetchReady: props => !!props.currencyMeta,
    collector: props => props.currencyMeta,
  },
];
