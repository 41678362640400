import React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import UpdateIcon from '@material-ui/icons/Update';
import CancelIcon from '@material-ui/icons/Cancel';
import PublishIcon from '@material-ui/icons/Publish';
import DoneIcon from '@material-ui/icons/Done';
import RemoveIcon from '@material-ui/icons/Remove';
import ReconcileIcon from '@material-ui/icons/CallMerge';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import CompareArrowsTwoTone from '@material-ui/icons/CompareArrowsTwoTone';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  progressButtonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: green[400],
    position: 'absolute',
    top: '25%',
    left: '45%',
    marginTop: -6,
    marginLeft: -6,
  },
}));

function BaseButton(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    disabled = false,
    buttonText,
    children,
    iconClass,
    onClickPromise,
    onClick,
    color,
    ...otherProps
  } = props;

  const content = buttonText || children;
  const [isLoading, setIsLoading] = React.useState(false);

  const customStyle = {};
  if (color === 'primary') customStyle.color = theme.palette.secondary.main;
  else if (color === 'secondary') customStyle.color = theme.palette.primary.main;
  const IconClass = iconClass;

  return (
    <div className={classes.progressButtonWrapper}>
      <Button
        variant="contained"
        color={color}
        className={classes.button}
        onClick={(e) => {
          if (isLoading) return;
          onClick && onClick(e);
          if (onClickPromise) {
            const promise = onClickPromise();
            if (promise) {
              setIsLoading(true);
              promise.finally(() => setIsLoading(false));
            }
          }
        }}
        disabled={disabled}
        {...otherProps}
      >
        {
          iconClass && <IconClass className={classes.leftIcon} />
        }
        {content}
      </Button>
      {isLoading && (
        <CircularProgress
          thickness={6}
          size={30}
          className={classes.buttonProgress}
          style={customStyle}
        />
      )}
    </div>
  );
}

BaseButton.propTypes = {
  disabled: PropTypes.bool,
  buttonText: PropTypes.string,
  iconClass: PropTypes.object,
  onClickPromise: PropTypes.func,
  ...Button.propTypes,
};

BaseButton.defaultProps = {
  disabled: false,
  buttonText: '',
  iconClass: null,
  onClickPromise: null,
};

const createIconButton = (iconClass, color, buttonText) => props => (
  <BaseButton
    color={color}
    iconClass={iconClass}
    buttonText={buttonText}
    {...props}
  />
);
export const AddButton = createIconButton(AddIcon, 'primary', 'add');
export const UpdateButton = createIconButton(UpdateIcon, 'primary', 'update');
export const CloseButton = createIconButton(CancelIcon, 'secondary', 'close');
export const UploadButton = createIconButton(PublishIcon, 'primary', 'upload');
export const ApproveButton = createIconButton(DoneIcon, 'primary', 'convert');
export const RemoveButton = createIconButton(RemoveIcon, 'secondary', 'remove');
export const ReconcileButton = createIconButton(ReconcileIcon, 'primary', 'remove');
export const BackButton = createIconButton(ArrowBackIcon, 'primary', 'remove');
export const ProcessButton = createIconButton(SendIcon, 'primary', 'process');
export const ExportButton = createIconButton(PublishIcon, 'primary', 'export');
export const ImportButton = createIconButton(GetAppIcon, 'primary', 'import');
export const TransferButton = createIconButton(CompareArrowsTwoTone, 'primary', 'transfer');
export const EditButton = createIconButton(EditIcon, 'primary', 'edit');
export const DownloadButton = createIconButton(GetAppIcon, 'primary', 'download');
export const InfoButton = createIconButton(InfoIcon, 'primary', 'info');


export default BaseButton;
