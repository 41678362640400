/**
 * @flow
 * @relayHash b2582cae1f35021a7df170b355e0a594
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccountStatus = "ACCOUNT_APPROVED" | "ACCOUNT_CLOSED" | "ACCOUNT_NOT_INITIATED" | "ACCOUNT_PENDING" | "ACCOUNT_SUBMITTED" | "%future added value";
export type CloseAccountMutationVariables = {|
  accountId: string
|};
export type CloseAccountMutationResponse = {|
  +closeAccount: ?{|
    +account: ?{|
      +id: string,
      +updatedAt: any,
      +clientaccount: ?{|
        +id: string,
        +accountStatus: AccountStatus,
        +updatedAt: any,
      |},
    |}
  |}
|};
export type CloseAccountMutation = {|
  variables: CloseAccountMutationVariables,
  response: CloseAccountMutationResponse,
|};
*/


/*
mutation CloseAccountMutation(
  $accountId: ID!
) {
  closeAccount(accountId: $accountId) {
    account {
      id
      updatedAt
      clientaccount {
        id
        accountStatus
        updatedAt
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "closeAccount",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "accountId"
      }
    ],
    "concreteType": "CloseAccountAdmin",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "account",
        "storageKey": null,
        "args": null,
        "concreteType": "AccountNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "clientaccount",
            "storageKey": null,
            "args": null,
            "concreteType": "ClientAccountNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "accountStatus",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CloseAccountMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CloseAccountMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CloseAccountMutation",
    "id": null,
    "text": "mutation CloseAccountMutation(\n  $accountId: ID!\n) {\n  closeAccount(accountId: $accountId) {\n    account {\n      id\n      updatedAt\n      clientaccount {\n        id\n        accountStatus\n        updatedAt\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0e4861a0fd429dc621f5863997aaeff5';
module.exports = node;
