import { graphql } from 'react-relay';

const updateUserMobileNumber = graphql`
  mutation UpdateUserMobileNumberMutation(
    $userId: ID!
    $mobileNumber: String!
  ) {
    updateUserMobileNumber(
      userId: $userId
      mobileNumber: $mobileNumber
    ) {
      user {
        usermodel {
          id
          mobileNumber
          parsedMobileNumber {
            countrySymbol
            countryCode
            nationalNumber
            isValidNumber
          }
        }
        adviser {
          id
          mobileNumber
        }
      }
    }
  }
`;

export default updateUserMobileNumber;
