
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { commitMutation } from 'react-relay';
import { useCommonStyles } from '../../common/Styles';
import TenantLoader from '../../common/fieldLoaders/TenantLoader';
import environment from '../../../Environment';
import setAdviserMutation from '../../../mutations/SetAdviser';
import notiStack from '../../../common/notiStack';
import BottomGridContainer from '../../common/containers/BottomGridContainer';
import { UpdateButton } from '../../common/buttons/BaseButton';
import PopupAdviserSelectorField from '../../common/PopupAdviserSelectorField';

export default function SetAdviserForm(props) {
  const { clientAccount, onClose } = props;
  const { adviser: currentAdviser } = clientAccount;
  const { tenant: currentTenant } = clientAccount;

  const classes = useCommonStyles();
  const [tenant, setTenant] = useState(currentTenant);
  const [adviser, setAdviser] = useState(currentAdviser);

  const onChangeAdviser = (newAdviser) => {
    setAdviser(newAdviser);
    if (newAdviser) {
      setTenant(newAdviser.parentTenant);
    }
  };

  const onChangeTenant = (newTenant) => {
    setTenant(newTenant);
  };

  const assignTenant = () => {
    if (!tenant) {
      notiStack.warning('Must specify tenant');
      return;
    }

    const variables = {
      clientAccountId: clientAccount.id,
      tenantId: tenant ? tenant.id : null,
      adviserId: adviser ? adviser.id : null,
    };

    commitMutation(environment, {
      mutation: setAdviserMutation,
      variables,
      onCompleted: (response) => {
        const { setAdviser: setAdviserResponse } = response;
        if (setAdviserResponse) {
          const { adviser: newAdviser } = setAdviserResponse.clientAccount;
          if (newAdviser) {
            notiStack.success(`Adviser set to ${newAdviser.firstName} ${newAdviser.lastName}`);
          } else {
            notiStack.success('Detached from adviser');
          }
          if (onClose) onClose();
        }
      },
    });
  };

  return (
    <div className={classes.drawerRoot}>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Set Adviser
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <PopupAdviserSelectorField
            key={adviser}
            fullWidth
            caption="Adviser"
            onSetNull={() => setAdviser(null)}
            onSelect={onChangeAdviser}
            defaultValue={adviser}
          />
        </Grid>

        <Grid item xs={12}>
          <TenantLoader
            key={tenant ? tenant.id : 'null'}
            className={classes.fromControlDrawer}
            label="Tenant"
            onChange={onChangeTenant}
            defaultValue={tenant}
            disabled={adviser}
          />
        </Grid>

        <Grid item xs={12}>
          <BottomGridContainer>
            <Grid item>
              <UpdateButton
                buttonText="Adviser"
                onClick={assignTenant}
              />
            </Grid>
          </BottomGridContainer>
        </Grid>

      </Grid>
    </div>
  );
}
