import { graphql } from 'react-relay';

const updateClientAccountFeeTemplate = graphql`
  mutation UpdateClientAccountFeeTemplateMutation(
    $clientAccountId: ID!
    $feeTemplateId: ID
  ) {
    updateClientAccountFeeTemplate(
      clientAccountId: $clientAccountId
      feeTemplateId: $feeTemplateId
    ){
      clientAccount {
        accountPtr {
          ...AccountProfile_account
        }
      }
    }
  }
`;

export default updateClientAccountFeeTemplate;
