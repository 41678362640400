import React from 'react';
import PropTypes from 'prop-types';
import TableFieldMeta from '../../common/TableFieldMeta';
import VerbModifier from './VerbModifier';
import EnumListerModifierDataTable from '../common/table/EnumListerModifierDataTable';
import EnumValue from '../common/EnumValue';


function AdminPermissionRow(props) {
  const {
    permission,
    onUpdateRecord,
  } = props;
  return (
    <VerbModifier
      resource={permission.resource}
      verb={permission.verb}
      onSetVerb={(resource, newVerb) => {
        permission.verb = newVerb;
        onUpdateRecord && onUpdateRecord(permission);
      }}
    />
  );
}

export default function AdminPermissions(props) {
  const {
    permissions,
    onSetPermissions,
  } = props;

  const [members] = React.useState({ permissions });
  const updateRecords = (n) => {
    const idx = members.permissions.findIndex(e => e.resource === n.resource);
    if (idx === -1) members.permissions.push(n);
    else members.permissions[idx] = n;
    const filtered = members.permissions.filter(e => e.verb !== null);
    onSetPermissions && onSetPermissions(filtered);
  };

  return (
    <EnumListerModifierDataTable
      enumType="AdminResource"
      keyFinder={(record, metaKey) => record.resource === metaKey}
      keyInitiator={metaKey => ({
        resource: metaKey,
        verb: null,
      })}
      columnMeta={[
        TableFieldMeta.field('resource', 'Resource', {
          accessor: node => (
            <span>
              {`${node.resource} : `}
              <EnumValue enumType="AdminResource" enumValue={node.resource} />
            </span>
          ),
        }),
        // TableFieldMeta.enum('AdminResource', '', node => node.resource),
        TableFieldMeta.fieldCell('verb', 'Access', node => (
          <AdminPermissionRow
            key={node.value.resource}
            permission={node.value}
            onUpdateRecord={updateRecords}
          />
        ), { align: 'center' }),
      ]}
      dataNodes={permissions}
    />

  );
}

AdminPermissions.propTypes = {
  permissions: PropTypes.array.isRequired,
};

AdminPermissions.defaultProps = {
};
