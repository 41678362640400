import { withRouter } from 'react-router-dom';
import { withTabItems } from '../common/RoutableTabContainer';
import DailyReconcileList from './DailyReconcileList';
import RangeReconcileList from './RangeReconcileList';


const ReconcileTabContainer = withTabItems(
  [
    {
      label: 'Daily',
      url: 'daily',
      comp: DailyReconcileList,
    },
    {
      label: 'Range',
      url: 'range',
      comp: RangeReconcileList,
    },
  ],
);

export default withRouter(ReconcileTabContainer);
