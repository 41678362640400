import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import AdminPermissionForm, { AdminPermissionDrawerContainer } from './AdminPermissionForm';

import TableButton from '../common/buttons/TableButton';

export default function AdminPermissionDrawer(props) {
  const { user } = props;
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  return (
    <>
      <TableButton
        color="primary"
        onClick={() => setDrawerOpen(true)}
      >
        Modify
      </TableButton>

      <Drawer
        open={drawerOpen}
        anchor="right"
        onClose={() => setDrawerOpen(false)}
      >
        <AdminPermissionDrawerContainer>
          <AdminPermissionForm
            user={user}
            onClose={() => setDrawerOpen(false)}
          />
        </AdminPermissionDrawerContainer>
      </Drawer>
    </>

  );
}
