import React from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import {
  Paper, TableBody, TableCell, TableHead, TableRow, Typography, 
} from '@material-ui/core';
import { useCommonStyles } from '../common/Styles';

function UserSecurityQuestionsTable(props) {
  const { securityQuestions } = props;
  const classes = useCommonStyles();

  return (
    <Grid item xs={12}>
      <Paper className={classes.tableHolderRoot}>
        <div className={classes.widgetHeadingContainer}>
          <Typography
            component="h6"
            variant="subtitle2"
            gutterBottom
          >
            Security Questions
          </Typography>
        </div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Answer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              securityQuestions.edges.map(
                securityQuestion => (
                  <TableRow>
                    <TableCell>{securityQuestion.node.question}</TableCell>
                    <TableCell>{securityQuestion.node.answer}</TableCell>
                  </TableRow>
                ),
              )
            }
          </TableBody>
        </Table>
      </Paper>
    </Grid>
  );
}

export default UserSecurityQuestionsTable;
