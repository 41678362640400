/**
 * @flow
 * @relayHash 4d0d938ac42013f732c06b80240c969c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateUserAddressMutationVariables = {|
  userId: string,
  billingAddress?: ?string,
  mailingAddress?: ?string,
|};
export type UpdateUserAddressMutationResponse = {|
  +updateUserAddress: ?{|
    +user: ?{|
      +usermodel: ?{|
        +id: string,
        +billingAddress: ?string,
        +mailingAddress: ?string,
      |}
    |}
  |}
|};
export type UpdateUserAddressMutation = {|
  variables: UpdateUserAddressMutationVariables,
  response: UpdateUserAddressMutationResponse,
|};
*/


/*
mutation UpdateUserAddressMutation(
  $userId: ID!
  $billingAddress: String
  $mailingAddress: String
) {
  updateUserAddress(userId: $userId, billingAddress: $billingAddress, mailingAddress: $mailingAddress) {
    user {
      usermodel {
        id
        billingAddress
        mailingAddress
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "billingAddress",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "mailingAddress",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "billingAddress",
    "variableName": "billingAddress"
  },
  {
    "kind": "Variable",
    "name": "mailingAddress",
    "variableName": "mailingAddress"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "usermodel",
  "storageKey": null,
  "args": null,
  "concreteType": "UserModelNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "billingAddress",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mailingAddress",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateUserAddressMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateUserAddress",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserAddress",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateUserAddressMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateUserAddress",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserAddress",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateUserAddressMutation",
    "id": null,
    "text": "mutation UpdateUserAddressMutation(\n  $userId: ID!\n  $billingAddress: String\n  $mailingAddress: String\n) {\n  updateUserAddress(userId: $userId, billingAddress: $billingAddress, mailingAddress: $mailingAddress) {\n    user {\n      usermodel {\n        id\n        billingAddress\n        mailingAddress\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8997e0cd792d618a4ae7eada854c885e';
module.exports = node;
