import React from 'react';
import PropTypes from 'prop-types';
import AdviserSelectionDlg from './dialogs/AdviserSelectionDlg';
import PopupEntitySelectorField from './PopupEntitySelectorField';
import { toLocal } from '../../helpers/ID';

function PopupAdviserSelectorField(props) {
  const {
    children,
    caption,
    ...otherProps
  } = props;

  const adviserDescriber = (adviser) => {
    if (!adviser) return '';
    return `${adviser.firstName} ${adviser.lastName}`;
  };

  return (
    <PopupEntitySelectorField
      {...otherProps}
      caption={caption}
      searchDlgComp={AdviserSelectionDlg}
      helperTextDescriber={adviser => (adviser ? `${toLocal(adviser.id)} : ${adviser.email}` : 'Select Adviser...')}
      valueDescriber={adviser => adviserDescriber(adviser)}
    />
  );
}

PopupAdviserSelectorField.propTypes = {
  caption: PropTypes.string,
};

PopupAdviserSelectorField.defaultProps = {
  caption: 'Adviser',
};

export default PopupAdviserSelectorField;
