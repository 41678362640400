import { graphql } from 'react-relay';

const updateClientAccount = graphql`
  mutation UpdateClientAccountMutation(
    $clientAccountId: ID!
    $name: String!
  ) {
    updateClientAccount (
      clientAccountId: $clientAccountId
      name: $name
    ){
      clientAccount {
        accountPtr {
          ...AccountProfile_account
        }
      }
    }
  }
`;

export default updateClientAccount;
