import { graphql } from 'react-relay';

// language=GraphQL
const generateABAForCTRBatch = graphql`
  mutation GenerateABAForCTRBatchMutation (
    $cashTransferRequestBatchId: ID!
  ) {
      generateAbaForCtrBatch(cashTransferRequestBatchId: $cashTransferRequestBatchId) {
        abaText
      }
    }
`;

export default generateABAForCTRBatch;
