import React from 'react';
import {
  TextField,
  Grid,
  Select,
  MenuItem,
} from '@material-ui/core';

const AUS_STATE_OPTIONS = [
  { description: 'South Australia', value: 'SA' },
  { description: 'Queensland', value: 'QLD' },
  { description: 'New South Wales', value: 'NSW' },
  { description: 'Australian Capital Territory', value: 'ACT' },
  { description: 'Victoria', value: 'VIC' },
  { description: 'Western Australia', value: 'WA' },
  { description: 'Northern Territory', value: 'NT' },
  { description: 'Tasmania', value: 'TAS' },
];

export default function ManualAddressField({
  defaultValue,
  manualAddress,
  setManualAddress,
}) {
  const handleChange = (key, value) => {
    const updatedValue = { [key]: value };

    setManualAddress(address => ({
      ...address,
      ...updatedValue,
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField placeholder="Apt, suite, unit, building, floor, etc." fullWidth required onChange={e => handleChange('subpremise', e.target.value)} value={manualAddress.subpremise} defaultValue={defaultValue.googlemaps_supported === false ? defaultValue.subpremise : ''} />
      </Grid>
      <Grid item xs={6}>
        <TextField placeholder="#" fullWidth required onChange={e => handleChange('street_number', e.target.value)} value={manualAddress.street_number} defaultValue={defaultValue.googlemaps_supported === false ? defaultValue.street_number : ''} />
      </Grid>
      <Grid item xs={6}>
        <TextField placeholder="Street address" fullWidth required onChange={e => handleChange('route', e.target.value)} value={manualAddress.route} defaultValue={defaultValue.googlemaps_supported === false ? defaultValue.route : ''} />
      </Grid>
      <Grid item xs={6}>
        <TextField placeholder="City" fullWidth required onChange={e => handleChange('locality', e.target.value)} value={manualAddress.locality} defaultValue={defaultValue.googlemaps_supported === false ? defaultValue.locality : ''} />
      </Grid>
      <Grid item xs={6}>
        <Select
          displayEmpty
          fullWidth
          required
          onChange={e => handleChange('administrative_area_level_1', e.target.value)}
          defaultValue={defaultValue.googlemaps_supported === false ? defaultValue.administrative_area_level_1 : ''}
          value={manualAddress.administrative_area_level_1}

        >
          <MenuItem disabled value="">
            <div style={{ color: '#B5B5B5' }}>State</div>
          </MenuItem>
          {AUS_STATE_OPTIONS.map(data => (
            <MenuItem value={data.value}>{data.description}</MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={6}>
        <TextField placeholder="Country" fullWidth required onChange={e => handleChange('country', e.target.value)} value={manualAddress.country} defaultValue={defaultValue.googlemaps_supported === false ? defaultValue.country : ''} />
      </Grid>
      <Grid item xs={6}>
        <TextField placeholder="Post code" fullWidth required onChange={e => handleChange('postal_code', e.target.value)} value={manualAddress.postal_code} defaultValue={defaultValue.googlemaps_supported === false ? defaultValue.postal_code : ''} />
      </Grid>
    </Grid>
  );
}
