import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { Drawer } from '@material-ui/core';
import TableButton from '../../common/buttons/TableButton';
import VerticalTable from '../../common/VerticalTable';
import TableFieldMeta, { Gid } from '../../../common/TableFieldMeta';
import MoreButton from '../../common/buttons/MoreButton';
import { fromGlobalId, toLocal } from '../../../helpers/ID';
import { VerticalTableWidget } from '../../common/VerticalTableWidget';
import BaseButton from '../../common/buttons/BaseButton';
import updateClientAccount from '../../../mutations/UpdateClientAccount';
import SetAdviserForm from './SetAdviserForm';
import { useCommonStyles } from '../../common/Styles';
import NoteButton from '../../common/notes/NoteButton';
import doCommit, { doCommitPromise } from '../../../common/commit';
import TenantFeeTemplateDlg from '../../tenant/TenantFeeTemplateDlg';
import updateClientAccountFeeTemplate from '../../../mutations/UpdateClientAccountFeeTemplate';
import { isRecurNull } from '../../../common/helpers';


function ClientAccountInfo(props) {
  const { account } = props;
  const [accountChangeDialogOpen, setAccountChangeDialogOpen] = useState(false);
  const [adviserDrawerOpen, setAdviserDrawerOpen] = useState(false);
  const [feeTemplateDlgShown, setFeeTemplateDlgShown] = useState(false);
  const [localVars] = useState({ accountName: '' });
  const clientFeeTemplate = isRecurNull(account, ['clientaccount', 'feeTemplate']);

  const classes = useCommonStyles();
  const columns = [
    ['Name', node => (
      <Grid container spacing={1} justify="flex-end">
        <Grid item>
          {node.name}
        </Grid>
        <Grid item>
          <TableButton
            color="primary"
            onClick={() => {
              localVars.accountName = node.name;
              setAccountChangeDialogOpen(true);
            }}
          >
            Change
          </TableButton>
        </Grid>
      </Grid>
    )],
    ['Status', VerticalTable.enum('accountStatus', 'AccountStatus')],
    ['Account Type', VerticalTable.enum('accountType', 'AccountType')],
    ['Reference Number', 'referenceNumber'],
    ['Created At', TableFieldMeta.createdAt.accessor],
    ['Updated At', TableFieldMeta.updatedAt.accessor],
    ['Adviser', node => (
      <Grid container spacing={1} justify="flex-end">
        {node.adviser ? (
          <React.Fragment>
            <Grid item>
              {`${node.adviser.firstName} ${node.adviser.lastName}`}
            </Grid>
            <Grid item>
              <MoreButton
                onClick={() => setAdviserDrawerOpen(true)}
              />
            </Grid>
          </React.Fragment>
        ) : (
          <Grid item>
            <TableButton
              color="primary"
              onClick={() => setAdviserDrawerOpen(true)}
            >
              Set Adviser
            </TableButton>
          </Grid>
        )}
      </Grid>
    )],
    ['Tenant', node => <Link href={`/tenant/${fromGlobalId(node.tenant.id)[1]}`}>{node.tenant.name}</Link>],
    ['Notes', node => (
      <NoteButton
        internalDrawer
        notesOwner={node.accountPtr}
      />
    )],
    ['Application', (node) => {
      if (!node.tradingApplication) {
        return <React.Fragment />;
      }
      const applicationLocalId = fromGlobalId(node.tradingApplication.id)[1];
      return <Link href={`/application/${applicationLocalId}`}>{applicationLocalId}</Link>;
    }],
    ['Fee Template', node => (
      <Grid container spacing={2} justify="flex-end">
        <Grid item>
          {clientFeeTemplate && (
            <Link
              href={`/fee-template/${toLocal(clientFeeTemplate.id)}`}
            >
              {clientFeeTemplate.name}
            </Link>
          )}
        </Grid>
        <Grid item>
          <TableButton
            color="primary"
            onClick={() => setFeeTemplateDlgShown(true)}
          >
            Set
          </TableButton>
        </Grid>
      </Grid>
    )],
    ['Gid', node => <Gid gid={node.id} />],
  ];

  return (
    <>
      <Dialog
        key={account.updatedAt}
        open={accountChangeDialogOpen}
        onClose={() => setAccountChangeDialogOpen(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialogSingleInput}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <DialogContentText>
            Change account name
          </DialogContentText>
          <TextField
            key={account.updatedAt}
            autoFocus
            defaultValue={account.name}
            margin="dense"
            id="name"
            label="Account Name"
            fullWidth
            onChange={(e) => {
              localVars.accountName = e.target.value;
            }}
          >
            {localVars.accountName}
          </TextField>
        </DialogContent>
        <DialogActions>
          <BaseButton
            onClick={() => setAccountChangeDialogOpen(false)}
            color="primary"
            buttonText="cancel"
          />
          <BaseButton
            onClickPromise={() => doCommitPromise(
              updateClientAccount,
              {
                clientAccountId: account.clientaccount.id,
                name: localVars.accountName,
              },
              () => setAccountChangeDialogOpen(false),
            )}
            color="primary"
            buttonText="change"
          />
        </DialogActions>
      </Dialog>


      <Dialog
        open={feeTemplateDlgShown}
        onClose={() => setFeeTemplateDlgShown(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialogSingleInput}
        maxWidth="sm"
        fullWidth
      >
        <TenantFeeTemplateDlg
          feeTemplate={null}
          onClose={() => setFeeTemplateDlgShown(false)}
          onFeeTemplate={newFt => doCommit(
            updateClientAccountFeeTemplate,
            {
              clientAccountId: account.clientaccount.id,
              feeTemplateId: newFt && newFt.id,
            },
            () => setFeeTemplateDlgShown(false),
            () => null,
          )}
        />
      </Dialog>

      {/*Adviser drawer*/}
      <Drawer
        anchor="right"
        open={adviserDrawerOpen}
        onClose={() => setAdviserDrawerOpen(false)}
      >
        <SetAdviserForm
          clientAccount={account.clientaccount}
          onClose={() => setAdviserDrawerOpen(false)}
        />
      </Drawer>

      <VerticalTableWidget
        xs={12}
        columnMeta={columns}
        dataNode={account.clientaccount}
        showHeadings={false}
        title="Client Account Details"
      />
    </>
  );
}

export default ClientAccountInfo;
