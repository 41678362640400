import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function Aba(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M6,8.3h-3.7c-.6,0-1.2.5-1.2,1.2v6.2h1.8v-1.8h2.5v1.8h1.8v-6.2c0-.7-.5-1.2-1.2-1.2ZM5.4,12h-2.5v-1.8h2.5v1.8Z"
      />
      <path
        d="M20.5,8.3h-3.7c-.6,0-1.2.5-1.2,1.2v6.2h1.8v-1.8h2.5v1.8h1.8v-6.2c0-.7-.5-1.2-1.2-1.2ZM19.9,12h-2.5v-1.8h2.5v1.8Z"
      />
      <path
        d="M14.5,10.2c0-1-.9-1.8-1.8-1.8h-4.3v7.4h4.3c1,0,1.8-.9,1.8-1.8v-.9c0-.2,0-.6-.2-.9.1-.2.2-.5.2-.9v-1.2h0ZM10.2,10.2h2.5v1h-2.5v-1ZM12.6,13.8h-2.5v-.9h2.5v.9Z"
      />
    </SvgIcon>
  );
}
