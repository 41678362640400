/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type FeeType = "ADF" | "ASB" | "AVH" | "BUF" | "CHI" | "CHIP" | "CHUP" | "COR" | "CRDT" | "FXB" | "GST" | "INT" | "MGN" | "MSB" | "PLF" | "PLT" | "PRO" | "RTA" | "RTP" | "SIPP" | "STB" | "STBV" | "TAX" | "TRA" | "VHF" | "WDR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ChargesList_viewer$ref: FragmentReference;
declare export opaque type ChargesList_viewer$fragmentType: ChargesList_viewer$ref;
export type ChargesList_viewer = {|
  +manualAccountCharges: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +amount: number,
        +description: ?string,
        +createdAt: any,
        +updatedAt: any,
        +feeType: FeeType,
        +currency: Currency,
        +account: {|
          +id: string,
          +name: ?string,
        |},
        +accountchargePtr: {|
          +accountchargetenantportionSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +quantity: number,
                +tenant: ?{|
                  +name: string
                |},
              |}
            |}>
          |}
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: ChargesList_viewer$ref,
|};
export type ChargesList_viewer$data = ChargesList_viewer;
export type ChargesList_viewer$key = {
  +$data?: ChargesList_viewer$data,
  +$fragmentRefs: ChargesList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ChargesList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "accountId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "manualAccountCharges",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "accountId",
          "variableName": "accountId"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-createdAt"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "concreteType": "ManualAccountChargeNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ManualAccountChargeNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ManualAccountChargeNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "amount",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "description",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "updatedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "feeType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "currency",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "account",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "AccountNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "accountchargePtr",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "AccountChargeNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "accountchargetenantportionSet",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "AccountChargeTenantPortionNodeConnection",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "edges",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "AccountChargeTenantPortionNodeEdge",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "node",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "AccountChargeTenantPortionNode",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "quantity",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "tenant",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "TenantNode",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/)
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aed6f8c437fcbd113359ae8c6ee137f5';
module.exports = node;
