/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type SubscriberType = "ADV" | "CLIENT" | "%future added value";
export type SubscriptionCode = "RTP_CU" | "RTP_CX" | "RTP_OC" | "%future added value";
export type UserType = "ADVISER" | "CLIENT" | "TENANT" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserDetails_fetchedUser$ref: FragmentReference;
declare export opaque type UserDetails_fetchedUser$fragmentType: UserDetails_fetchedUser$ref;
export type UserDetails_fetchedUser = {|
  +dateJoined: any,
  +firstName: string,
  +lastName: string,
  +lastLogin: ?any,
  +email: string,
  +username: string,
  +userType: ?UserType,
  +id: string,
  +isActive: boolean,
  +isStaff: boolean,
  +emailVerified: ?boolean,
  +mfaConfigured: ?boolean,
  +usermodel: ?{|
    +id: string,
    +mobileNumber: string,
    +billingAddress: ?string,
    +mailingAddress: ?string,
    +parsedMobileNumber: ?{|
      +countrySymbol: ?string,
      +countryCode: ?string,
      +nationalNumber: ?string,
      +isValidNumber: ?boolean,
    |},
    +accounts: {|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +userPtr: {|
      +id: string
    |},
  |},
  +adviser: ?{|
    +id: string,
    +userPtr: {|
      +fullName: ?string
    |},
    +mobileNumber: string,
    +parentTenant: {|
      +id: string,
      +name: string,
    |},
  |},
  +securityQuestions: {|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +question: ?string,
        +answer: string,
      |}
    |}>
  |},
  +usersubscriptionSet: {|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +subscriberType: SubscriberType,
        +id: string,
        +createdAt: any,
        +updatedAt: any,
        +subscriptionTemplate: {|
          +code: SubscriptionCode,
          +name: string,
        |},
        +user: {|
          +id: string
        |},
        +billPeriodStart: any,
        +billPeriodEnd: any,
        +pendingCancel: boolean,
        +active: boolean,
        +paymentAccount: {|
          +id: string,
          +name: ?string,
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: UserDetails_fetchedUser$ref,
|};
export type UserDetails_fetchedUser$data = UserDetails_fetchedUser;
export type UserDetails_fetchedUser$key = {
  +$data?: UserDetails_fetchedUser$data,
  +$fragmentRefs: UserDetails_fetchedUser$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mobileNumber",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v2/*: any*/)
],
v4 = [
  (v0/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "UserDetails_fetchedUser",
  "type": "UserNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dateJoined",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastLogin",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "userType",
      "args": null,
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isActive",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isStaff",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "emailVerified",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mfaConfigured",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "usermodel",
      "storageKey": null,
      "args": null,
      "concreteType": "UserModelNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "billingAddress",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "mailingAddress",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "parsedMobileNumber",
          "storageKey": null,
          "args": null,
          "concreteType": "ParsedMobileNumber",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "countrySymbol",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "countryCode",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "nationalNumber",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isValidNumber",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "accounts",
          "storageKey": null,
          "args": null,
          "concreteType": "ClientAccountNodeConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "ClientAccountNodeEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ClientAccountNode",
                  "plural": false,
                  "selections": (v3/*: any*/)
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "userPtr",
          "storageKey": null,
          "args": null,
          "concreteType": "UserNode",
          "plural": false,
          "selections": (v4/*: any*/)
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "adviser",
      "storageKey": null,
      "args": null,
      "concreteType": "AdviserNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "userPtr",
          "storageKey": null,
          "args": null,
          "concreteType": "UserNode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "fullName",
              "args": null,
              "storageKey": null
            }
          ]
        },
        (v1/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "parentTenant",
          "storageKey": null,
          "args": null,
          "concreteType": "TenantNode",
          "plural": false,
          "selections": (v3/*: any*/)
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "securityQuestions",
      "storageKey": null,
      "args": null,
      "concreteType": "SecurityQuestionNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "SecurityQuestionNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "SecurityQuestionNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "question",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "answer",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "usersubscriptionSet",
      "storageKey": null,
      "args": null,
      "concreteType": "UserSubscriptionNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "UserSubscriptionNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "UserSubscriptionNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "subscriberType",
                  "args": null,
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "updatedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "subscriptionTemplate",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "SubscriptionTemplateNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "code",
                      "args": null,
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "user",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserNode",
                  "plural": false,
                  "selections": (v4/*: any*/)
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "billPeriodStart",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "billPeriodEnd",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "pendingCancel",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "active",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "paymentAccount",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "AccountNode",
                  "plural": false,
                  "selections": (v3/*: any*/)
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a32f1cc2e9ad362e06688e7ae72316aa';
module.exports = node;
