/**
 * @flow
 * @relayHash 4c8cd2b8909b166b20eac01afae35fc8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReportState = "ERR" | "FIN" | "REQ" | "%future added value";
export type ReportTrigger = "EOF" | "OG" | "SG" | "UG" | "%future added value";
export type ReportType = "AAR" | "ACC" | "ACT" | "AHD" | "AIR" | "BRO" | "CGT" | "CRP" | "CTR" | "CUS" | "FUM" | "FXT" | "HOLD" | "INC" | "INV" | "OCA" | "OFE" | "ORD" | "POT" | "RMAD" | "TRD" | "TXN" | "%future added value";
export type ReportUploadCustomMutationVariables = {|
  clientAccountId: string,
  name?: ?string,
  pdf?: ?any,
  xlsx?: ?any,
|};
export type ReportUploadCustomMutationResponse = {|
  +uploadCustomReport: ?{|
    +report: ?{|
      +reportPtr: {|
        +id: string,
        +createdAt: any,
        +updatedAt: any,
        +pdfUrl: ?string,
        +xlsxUrl: ?string,
        +reportType: ReportType,
        +trigger: ReportTrigger,
        +state: ReportState,
        +pdf: ?string,
        +xlsx: ?string,
        +clientAccount: ?{|
          +id: string,
          +name: ?string,
          +accountPtr: {|
            +id: string,
            +name: ?string,
          |},
        |},
        +customreport: ?{|
          +name: ?string,
          +year: ?number,
        |},
      |}
    |}
  |}
|};
export type ReportUploadCustomMutation = {|
  variables: ReportUploadCustomMutationVariables,
  response: ReportUploadCustomMutationResponse,
|};
*/


/*
mutation ReportUploadCustomMutation(
  $clientAccountId: ID!
  $name: String
  $pdf: Upload
  $xlsx: Upload
) {
  uploadCustomReport(clientAccountId: $clientAccountId, name: $name, pdf: $pdf, xlsx: $xlsx) {
    report {
      reportPtr {
        id
        createdAt
        updatedAt
        pdfUrl
        xlsxUrl
        reportType
        trigger
        state
        pdf
        xlsx
        clientAccount {
          id
          name
          accountPtr {
            id
            name
          }
        }
        customreport {
          name
          year
          id
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "clientAccountId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "pdf",
    "type": "Upload",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "xlsx",
    "type": "Upload",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "clientAccountId",
    "variableName": "clientAccountId"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  },
  {
    "kind": "Variable",
    "name": "pdf",
    "variableName": "pdf"
  },
  {
    "kind": "Variable",
    "name": "xlsx",
    "variableName": "xlsx"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pdfUrl",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "xlsxUrl",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reportType",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "trigger",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pdf",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "xlsx",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "clientAccount",
  "storageKey": null,
  "args": null,
  "concreteType": "ClientAccountNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v12/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "accountPtr",
      "storageKey": null,
      "args": null,
      "concreteType": "AccountNode",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v12/*: any*/)
      ]
    }
  ]
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "year",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReportUploadCustomMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "uploadCustomReport",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UploadCustomReport",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "report",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomReportNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "reportPtr",
                "storageKey": null,
                "args": null,
                "concreteType": "ReportNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v13/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "customreport",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomReportNode",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v14/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ReportUploadCustomMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "uploadCustomReport",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UploadCustomReport",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "report",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomReportNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "reportPtr",
                "storageKey": null,
                "args": null,
                "concreteType": "ReportNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v13/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "customreport",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomReportNode",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v14/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "ReportUploadCustomMutation",
    "id": null,
    "text": "mutation ReportUploadCustomMutation(\n  $clientAccountId: ID!\n  $name: String\n  $pdf: Upload\n  $xlsx: Upload\n) {\n  uploadCustomReport(clientAccountId: $clientAccountId, name: $name, pdf: $pdf, xlsx: $xlsx) {\n    report {\n      reportPtr {\n        id\n        createdAt\n        updatedAt\n        pdfUrl\n        xlsxUrl\n        reportType\n        trigger\n        state\n        pdf\n        xlsx\n        clientAccount {\n          id\n          name\n          accountPtr {\n            id\n            name\n          }\n        }\n        customreport {\n          name\n          year\n          id\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a349d79f8dc99dcb4b8ba8e2e0b56ee6';
module.exports = node;
