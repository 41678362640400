/**
 * @flow
 * @relayHash 0534fafab8a59671b8280d1bc635eaee
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VueHistoryQueryVariables = {|
  vueId?: ?string
|};
export type VueHistoryQueryResponse = {|
  +viewer: ?{|
    +vues: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +createdAt: any,
          +updatedAt: any,
          +published: ?boolean,
          +publishedAt: ?any,
          +vueSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +createdAt: any,
                +published: ?boolean,
                +publishedAt: ?any,
                +vuecontractSet: {|
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +contract: {|
                        +symbol: string
                      |}
                    |}
                  |}>
                |},
              |}
            |}>
          |},
        |}
      |}>
    |}
  |}
|};
export type VueHistoryQuery = {|
  variables: VueHistoryQueryVariables,
  response: VueHistoryQueryResponse,
|};
*/


/*
query VueHistoryQuery(
  $vueId: ID
) {
  viewer {
    vues(id: $vueId) {
      edges {
        node {
          createdAt
          updatedAt
          published
          publishedAt
          vueSet(orderBy: "-publishedAt") {
            edges {
              node {
                createdAt
                published
                publishedAt
                vuecontractSet {
                  edges {
                    node {
                      contract {
                        symbol
                        id
                      }
                      id
                    }
                  }
                }
                id
              }
            }
          }
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "vueId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "vueId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "published",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publishedAt",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "-publishedAt"
  }
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VueHistoryQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vues",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "VueNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "VueNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "VueNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "vueSet",
                        "storageKey": "vueSet(orderBy:\"-publishedAt\")",
                        "args": (v6/*: any*/),
                        "concreteType": "VueNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "VueNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "VueNode",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "vuecontractSet",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "VueContractNodeConnection",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "edges",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "VueContractNodeEdge",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "node",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "VueContractNode",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "contract",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "ContractNode",
                                                "plural": false,
                                                "selections": [
                                                  (v7/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VueHistoryQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vues",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "VueNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "VueNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "VueNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "vueSet",
                        "storageKey": "vueSet(orderBy:\"-publishedAt\")",
                        "args": (v6/*: any*/),
                        "concreteType": "VueNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "VueNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "VueNode",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "vuecontractSet",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "VueContractNodeConnection",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "edges",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "VueContractNodeEdge",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "node",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "VueContractNode",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "contract",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "ContractNode",
                                                "plural": false,
                                                "selections": [
                                                  (v7/*: any*/),
                                                  (v8/*: any*/)
                                                ]
                                              },
                                              (v8/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  (v8/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v8/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v8/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "VueHistoryQuery",
    "id": null,
    "text": "query VueHistoryQuery(\n  $vueId: ID\n) {\n  viewer {\n    vues(id: $vueId) {\n      edges {\n        node {\n          createdAt\n          updatedAt\n          published\n          publishedAt\n          vueSet(orderBy: \"-publishedAt\") {\n            edges {\n              node {\n                createdAt\n                published\n                publishedAt\n                vuecontractSet {\n                  edges {\n                    node {\n                      contract {\n                        symbol\n                        id\n                      }\n                      id\n                    }\n                  }\n                }\n                id\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '72b2bf9c407f5a0a99d38dfd45fece95';
module.exports = node;
