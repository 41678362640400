/**
 * @flow
 * @relayHash b5d5501c4a19765e76a909f01599fdec
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateUserMobileNumberMutationVariables = {|
  userId: string,
  mobileNumber: string,
|};
export type UpdateUserMobileNumberMutationResponse = {|
  +updateUserMobileNumber: ?{|
    +user: ?{|
      +usermodel: ?{|
        +id: string,
        +mobileNumber: string,
        +parsedMobileNumber: ?{|
          +countrySymbol: ?string,
          +countryCode: ?string,
          +nationalNumber: ?string,
          +isValidNumber: ?boolean,
        |},
      |},
      +adviser: ?{|
        +id: string,
        +mobileNumber: string,
      |},
    |}
  |}
|};
export type UpdateUserMobileNumberMutation = {|
  variables: UpdateUserMobileNumberMutationVariables,
  response: UpdateUserMobileNumberMutationResponse,
|};
*/


/*
mutation UpdateUserMobileNumberMutation(
  $userId: ID!
  $mobileNumber: String!
) {
  updateUserMobileNumber(userId: $userId, mobileNumber: $mobileNumber) {
    user {
      usermodel {
        id
        mobileNumber
        parsedMobileNumber {
          countrySymbol
          countryCode
          nationalNumber
          isValidNumber
        }
      }
      adviser {
        id
        mobileNumber
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "mobileNumber",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "mobileNumber",
    "variableName": "mobileNumber"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mobileNumber",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "usermodel",
  "storageKey": null,
  "args": null,
  "concreteType": "UserModelNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "parsedMobileNumber",
      "storageKey": null,
      "args": null,
      "concreteType": "ParsedMobileNumber",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "countrySymbol",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "countryCode",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "nationalNumber",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isValidNumber",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "adviser",
  "storageKey": null,
  "args": null,
  "concreteType": "AdviserNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateUserMobileNumberMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateUserMobileNumber",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserMobileNumber",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateUserMobileNumberMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateUserMobileNumber",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserMobileNumber",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateUserMobileNumberMutation",
    "id": null,
    "text": "mutation UpdateUserMobileNumberMutation(\n  $userId: ID!\n  $mobileNumber: String!\n) {\n  updateUserMobileNumber(userId: $userId, mobileNumber: $mobileNumber) {\n    user {\n      usermodel {\n        id\n        mobileNumber\n        parsedMobileNumber {\n          countrySymbol\n          countryCode\n          nationalNumber\n          isValidNumber\n        }\n      }\n      adviser {\n        id\n        mobileNumber\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4eabe09b40c12dfa77588b1c632cd477';
module.exports = node;
