import React from 'react';
import { withTabItems } from '../common/RoutableTabContainer';
import CashTransferMatchPane from './CashTransferMatchPane';
import OffMarketTransferList from './OffMarketTransferList';
import withSuspense from '../common/table/Suspention';

const TransferList = withSuspense(
  React.lazy(() => (process.env.DEPLOYMENT_CODE === 'UK'
    ? import('./uk/TransfersList')
    : import('./PositionTransferMatchPane'))),
);

export default withTabItems([
  {
    label: 'Cash',
    url: 'cash',
    comp: CashTransferMatchPane,
  },
  {
    label: 'Position',
    url: 'position',
    comp: TransferList,
  },
  {
    label: 'Off Market',
    url: 'off-market',
    comp: OffMarketTransferList,
  },
]);
