import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import BaseButton from '../buttons/BaseButton';
import AddressField from '../fields/AddressField';

function AddressModalDlgForm(props) {
  const {
    onChange,
    onChangePromise,
    onClose,
    title,
    fieldName,
    defaultValue,
  } = props;

  const [address, setAddress] = React.useState(defaultValue);
  const [validationStatus, setValidationStatus] = React.useState('');
  const updateState = (newAddress, newValidationStatus) => {
    setAddress(newAddress);
    setValidationStatus(newValidationStatus);
  };

  return (
    <React.Fragment>
      <DialogContent>
        <DialogContentText>
          {title}
        </DialogContentText>
        <AddressField
          id="change-address"
          label={fieldName}
          onChange={updateState}
          margin="dense"
          autoFocus
          fullWidth
          value={defaultValue}
        />
      </DialogContent>
      <DialogActions>
        <BaseButton
          onClick={() => (onClose && onClose())}
          color="primary"
          buttonText="cancel"
        />
        <BaseButton
          disabled={validationStatus !== 'success' && address}
          onClickPromise={() => (onChangePromise && onChangePromise(address))}
          onClick={() => (onChange && onChange(address))}
          color="primary"
          buttonText="change"
        />
      </DialogActions>
    </React.Fragment>
  );
}

AddressModalDlgForm.propTypes = {
  defaultValue: PropTypes.string,
};

AddressModalDlgForm.defaultProps = {
  defaultValue: null,
};

export default AddressModalDlgForm;
