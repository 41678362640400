/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ActionType = "AA" | "AACK" | "AAD" | "ABF" | "AC" | "ACCL" | "ACH" | "ACR" | "ACRO" | "ADIE" | "AEIE" | "AFNC" | "AFNU" | "AIAD" | "AIAE" | "AIAN" | "AIAR" | "AIAS" | "AIAT" | "AIRR" | "AIRS" | "AIRT" | "AM" | "ANC" | "AR" | "ARE" | "AS" | "ATCH" | "AU" | "BAAD" | "BACR" | "BADI" | "BAIG" | "BAPE" | "BAPI" | "BAPR" | "BAUI" | "BAUP" | "BAVE" | "BCC" | "BCU" | "BTIG" | "BTUI" | "CAREMFT" | "CASC" | "CASETFT" | "CASU" | "CIC" | "CISC" | "CISS" | "CISU" | "CIT" | "CIU" | "CIX" | "CTAPR" | "CTBLK" | "CTCAA" | "CTCAN" | "CTREC" | "CTREQ" | "CTUBL" | "CTVFY" | "GCBFV" | "GCBRA" | "GCBRC" | "GCBRD" | "GCBRF" | "GCBRI" | "GCBRV" | "GCPCO" | "GCPCR" | "GCPFL" | "GCPSU" | "MACC" | "MATC" | "MATU" | "MLTC" | "MLTU" | "OAA" | "OAR" | "OC" | "OCC" | "OCD" | "OM" | "OMCLA" | "OMCLD" | "OMCLN" | "OMCLR" | "OMCNI" | "OMD" | "OMEXP" | "OMOVA" | "OMOVD" | "OMPR" | "OMQA" | "OMRBC" | "OP" | "OPFC" | "OQ" | "OR" | "ORRCE" | "OTC" | "OX" | "SC" | "SCR" | "SF" | "SR" | "SRA" | "SRF" | "SS" | "SU" | "TBF" | "TCH" | "TCR" | "TIGC" | "TIGD" | "TIGU" | "TISC" | "TISU" | "TPC" | "TPU" | "TRSC" | "TRSU" | "ULMVF" | "ULMVS" | "ULNS" | "ULSC" | "ULUV" | "UMA" | "UMAF" | "UMAP" | "UMAR" | "UMARP" | "UMCP" | "UMCR" | "UMD" | "UMRRC" | "UMURP" | "UUE" | "UUF" | "UUL" | "UUP" | "UUSA" | "UUSD" | "UUU" | "UVE" | "VSPA" | "VSPR" | "VSPU" | "ZBTR" | "ZBTU" | "ZIR" | "ZITR" | "ZIU" | "ZPC" | "ZTP" | "ZTR" | "ZUC" | "ZUU" | "%future added value";
export type OrderApprovalContractStatus = "CAN" | "CRE" | "DIS" | "FAI" | "IND" | "PEN" | "%future added value";
export type OrderApprovalStatus = "CA" | "CD" | "EX" | "IN" | "OA" | "OD" | "RR" | "SA" | "SFA" | "%future added value";
export type OrderProposalType = "BT" | "IN" | "PR" | "%future added value";
export type OrderStatus = "CAN" | "CRE" | "FIL" | "INI" | "INM" | "PEX" | "PFL" | "POS" | "QUE" | "REJ" | "%future added value";
export type PortfolioOrderStatus = "CAN" | "COM" | "CRE" | "FIL" | "PEX" | "PFL" | "PRO" | "REJ" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderApproval_viewer$ref: FragmentReference;
declare export opaque type OrderApproval_viewer$fragmentType: OrderApproval_viewer$ref;
export type OrderApproval_viewer = {|
  +orderApprovals: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +portfolioOrder: ?{|
          +id: string,
          +status: PortfolioOrderStatus,
        |},
        +createdAt: any,
        +updatedAt: any,
        +approvedAt: ?any,
        +notifiedAt: ?any,
        +remindedAt: ?any,
        +proposal: {|
          +id: string,
          +type: OrderProposalType,
        |},
        +approvedBy: ?{|
          +fullName: ?string
        |},
        +status: OrderApprovalStatus,
        +portfolio: {|
          +id: string,
          +trackingVueName: ?string,
          +account: {|
            +name: ?string,
            +id: string,
          |},
        |},
        +actionLogs: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +actionDescription: string,
              +actionId: number,
              +actionTime: any,
              +actionType: ActionType,
              +user: ?{|
                +id: string,
                +fullName: ?string,
              |},
            |}
          |}>
        |},
        +orderclientapprovalSet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +pdfUrl: ?string,
            |}
          |}>
        |},
        +orderapprovalcontractSet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +createdAt: any,
              +updatedAt: any,
              +contract: {|
                +id: string,
                +symbol: string,
              |},
              +quantity: number,
              +orderStatus: OrderApprovalContractStatus,
              +order: ?{|
                +id: string,
                +brokerOrder: ?{|
                  +status: OrderStatus,
                  +matched: boolean,
                  +id: string,
                |},
              |},
            |}
          |}>
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: OrderApproval_viewer$ref,
|};
export type OrderApproval_viewer$data = OrderApproval_viewer;
export type OrderApproval_viewer$key = {
  +$data?: OrderApproval_viewer$data,
  +$fragmentRefs: OrderApproval_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "OrderApproval_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "approvalId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "orderApprovals",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "approvalId"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        }
      ],
      "concreteType": "OrderApprovalNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "OrderApprovalNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "OrderApprovalNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "portfolioOrder",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PortfolioOrderNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ]
                },
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "approvedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "notifiedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "remindedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "proposal",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "OrderProposalNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "type",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "approvedBy",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserNode",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/)
                  ]
                },
                (v1/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "portfolio",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PortfolioNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "trackingVueName",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "account",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "AccountNode",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "name",
                          "args": null,
                          "storageKey": null
                        },
                        (v0/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "actionLogs",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ActionLogUserRequestNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ActionLogUserRequestNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ActionLogUserRequestNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "actionDescription",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "actionId",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "actionTime",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "actionType",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "user",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "UserNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v4/*: any*/)
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "orderclientapprovalSet",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "OrderClientApprovalNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "OrderClientApprovalNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "OrderClientApprovalNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "pdfUrl",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "orderapprovalcontractSet",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "OrderApprovalContractNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "OrderApprovalContractNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "OrderApprovalContractNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "contract",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "ContractNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "symbol",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "quantity",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "orderStatus",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "order",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "OrderNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "brokerOrder",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "BrokerOrderNode",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    {
                                      "kind": "ScalarField",
                                      "alias": null,
                                      "name": "matched",
                                      "args": null,
                                      "storageKey": null
                                    },
                                    (v0/*: any*/)
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c636e9a8813698ac371b5c602dd97e89';
module.exports = node;
