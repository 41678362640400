import Grid from '@material-ui/core/Grid';
import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { MOMENT_DATE_FORMAT_GRAPHQL, muiGridSizes } from '../../../common/constants';
import { extractKeys } from '../../../common/helpers';


function DrawerGridItemDateField(props) {
  const {
    onChange,
    label,
    id,
    value,
    disableFuture,
    disabled,
    autoOk,
    ...otherProps
  } = props;
  const [sizeProps] = extractKeys(otherProps, muiGridSizes);
  return (
    <Grid item {...sizeProps}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="yyyy-MM-dd"
          id={id || (label && label.toLowerCase())}
          label={label}
          value={value}
          autoOk={autoOk}
          disabled={disabled}
          disableFuture={disableFuture}
          onChange={(inTime) => {
            if (inTime === null) {
              if (onChange) onChange(null);
              return;
            }
            const newExpiryDate = moment(inTime);
            if (newExpiryDate.isValid()) {
              if (onChange) onChange(newExpiryDate.format(MOMENT_DATE_FORMAT_GRAPHQL));
            }
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  );
}

DrawerGridItemDateField.propTypes = {
  disabled: PropTypes.bool,
};

DrawerGridItemDateField.defaultProps = {
  disabled: false,
};

export default DrawerGridItemDateField;
