import { graphql } from 'react-relay';

const updateUserStatus = graphql`
  mutation UpdateUserStatusMutation (
   $userId: ID!
   $isActive: Boolean
   $verifyEmail: Boolean
  ) {
    updateUserStatus(
      userId: $userId
      isActive: $isActive
      verifyEmail: $verifyEmail
    ) {
      user {
        emailVerified
        isActive
      }
    }
  }
`;

export default updateUserStatus;
