/**
 * @flow
 * @relayHash e2a8e502a710ea62342296dce4895ff5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReportType = "AAR" | "ACC" | "ACT" | "AHD" | "AIR" | "BRO" | "CGT" | "CRP" | "CTR" | "CUS" | "FUM" | "FXT" | "HOLD" | "INC" | "INV" | "OCA" | "OFE" | "ORD" | "POT" | "RMAD" | "TRD" | "TXN" | "%future added value";
export type GenerateRemittanceAdviceMutationInput = {|
  tenantId?: ?string,
  clientMutationId?: ?string,
|};
export type GenerateRemittanceAdviceMutationVariables = {|
  input: GenerateRemittanceAdviceMutationInput
|};
export type GenerateRemittanceAdviceMutationResponse = {|
  +generateRemittanceAdvice: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +messages: $ReadOnlyArray<string>,
      +field: string,
    |}>,
    +obj: ?{|
      +reportPtr: {|
        +id: string,
        +createdAt: any,
        +xlsxUrl: ?string,
        +reportType: ReportType,
      |}
    |},
  |}
|};
export type GenerateRemittanceAdviceMutation = {|
  variables: GenerateRemittanceAdviceMutationVariables,
  response: GenerateRemittanceAdviceMutationResponse,
|};
*/


/*
mutation GenerateRemittanceAdviceMutation(
  $input: GenerateRemittanceAdviceMutationInput!
) {
  generateRemittanceAdvice(input: $input) {
    errors {
      messages
      field
    }
    obj {
      reportPtr {
        id
        createdAt
        xlsxUrl
        reportType
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "GenerateRemittanceAdviceMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "messages",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "field",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "reportPtr",
  "storageKey": null,
  "args": null,
  "concreteType": "ReportNode",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "xlsxUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reportType",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GenerateRemittanceAdviceMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "generateRemittanceAdvice",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "GenerateRemittanceAdviceMutationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "obj",
            "storageKey": null,
            "args": null,
            "concreteType": "RemittanceAdviceNode",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "GenerateRemittanceAdviceMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "generateRemittanceAdvice",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "GenerateRemittanceAdviceMutationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "obj",
            "storageKey": null,
            "args": null,
            "concreteType": "RemittanceAdviceNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "GenerateRemittanceAdviceMutation",
    "id": null,
    "text": "mutation GenerateRemittanceAdviceMutation(\n  $input: GenerateRemittanceAdviceMutationInput!\n) {\n  generateRemittanceAdvice(input: $input) {\n    errors {\n      messages\n      field\n    }\n    obj {\n      reportPtr {\n        id\n        createdAt\n        xlsxUrl\n        reportType\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c905d442f85cec3e366c43b16b1a418';
module.exports = node;
