/**
 * @flow
 * @relayHash 6c0d52ab64691d49494cedd715919a75
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdminResource = "ACC" | "ADV" | "APP" | "BAC" | "BAT" | "CHR" | "CON" | "DEV" | "FEE" | "FXO" | "FXR" | "MPR" | "ODR" | "OMT" | "PER" | "RPT" | "SUB" | "SUP" | "TEN" | "TRA" | "TRD" | "USR" | "VUE" | "%future added value";
export type Verb = "MUTATE" | "READ" | "%future added value";
export type AdminPermissionType = {|
  resource?: ?AdminResource,
  verb?: ?Verb,
|};
export type SetAdminPermissionMutationVariables = {|
  userId: string,
  permissions?: ?$ReadOnlyArray<?AdminPermissionType>,
|};
export type SetAdminPermissionMutationResponse = {|
  +setAdminPermission: ?{|
    +user: ?{|
      +adminpermissionSet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +verb: Verb,
            +resource: AdminResource,
            +updatedAt: any,
          |}
        |}>
      |}
    |}
  |}
|};
export type SetAdminPermissionMutation = {|
  variables: SetAdminPermissionMutationVariables,
  response: SetAdminPermissionMutationResponse,
|};
*/


/*
mutation SetAdminPermissionMutation(
  $userId: ID!
  $permissions: [AdminPermissionType]
) {
  setAdminPermission(userId: $userId, permissions: $permissions) {
    user {
      adminpermissionSet {
        edges {
          node {
            id
            verb
            resource
            updatedAt
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "permissions",
    "type": "[AdminPermissionType]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "permissions",
    "variableName": "permissions"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "adminpermissionSet",
  "storageKey": null,
  "args": null,
  "concreteType": "AdminPermissionNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "AdminPermissionNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "AdminPermissionNode",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "verb",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "resource",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "updatedAt",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SetAdminPermissionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "setAdminPermission",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SetAdminPermission",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SetAdminPermissionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "setAdminPermission",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SetAdminPermission",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "SetAdminPermissionMutation",
    "id": null,
    "text": "mutation SetAdminPermissionMutation(\n  $userId: ID!\n  $permissions: [AdminPermissionType]\n) {\n  setAdminPermission(userId: $userId, permissions: $permissions) {\n    user {\n      adminpermissionSet {\n        edges {\n          node {\n            id\n            verb\n            resource\n            updatedAt\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cd097bcfd8b8064f92ab267f7785c69b';
module.exports = node;
