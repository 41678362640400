/**
 * @flow
 * @relayHash 9ded5531b4c33bc2069bb5baad0f6803
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AccountProfile_account$ref = any;
export type UpdateClientAccountMutationVariables = {|
  clientAccountId: string,
  name: string,
|};
export type UpdateClientAccountMutationResponse = {|
  +updateClientAccount: ?{|
    +clientAccount: ?{|
      +accountPtr: {|
        +$fragmentRefs: AccountProfile_account$ref
      |}
    |}
  |}
|};
export type UpdateClientAccountMutation = {|
  variables: UpdateClientAccountMutationVariables,
  response: UpdateClientAccountMutationResponse,
|};
*/


/*
mutation UpdateClientAccountMutation(
  $clientAccountId: ID!
  $name: String!
) {
  updateClientAccount(clientAccountId: $clientAccountId, name: $name) {
    clientAccount {
      accountPtr {
        ...AccountProfile_account
        id
      }
      id
    }
  }
}

fragment AccountProfile_account on AccountNode {
  id
  name
  actualCashBase
  availableCashBase
  stockValueBase
  updatedAt
  actualCashBalances {
    currency
    balance
  }
  notes {
    id
  }
  availableCashBalances {
    currency
    balance
  }
  holdings {
    portfolio {
      id
      trackingVue {
        name
        id
      }
    }
    contract {
      id
      symbol
      name
    }
    quantity
    id
  }
  brokerageCredits {
    edges {
      node {
        initialAmount
        remainingAmount
        id
      }
    }
  }
  clientaccount {
    id
    name
    accountStatus
    accountType
    createdAt
    updatedAt
    accruesInterest
    feeTemplate {
      id
      name
    }
    accountPtr {
      id
      notes {
        id
      }
    }
    adviser {
      id
      email
      firstName
      lastName
      parentTenant {
        name
        id
      }
    }
    tenant {
      id
      name
    }
    referenceNumber
    userModels {
      edges {
        node {
          id
          username
          email
          firstName
          lastName
        }
      }
    }
    activeEmailRecipients {
      id
    }
    tradingApplication {
      id
    }
  }
  bankAccounts {
    edges {
      node {
        id
        holderName
        bsbNumber
        accountNumber
        status
        dividendPayout
      }
    }
  }
  tenantaccount {
    name
    accountType
    createdAt
    updatedAt
    tenant {
      id
      name
      email
      firstName
      lastName
      username
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "clientAccountId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "clientAccountId",
    "variableName": "clientAccountId"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "currency",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "balance",
    "args": null,
    "storageKey": null
  }
],
v6 = [
  (v2/*: any*/)
],
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "notes",
  "storageKey": null,
  "args": null,
  "concreteType": "NotesNode",
  "plural": false,
  "selections": (v6/*: any*/)
},
v8 = [
  (v3/*: any*/),
  (v2/*: any*/)
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accountType",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v11 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateClientAccountMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateClientAccount",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateClientAccount",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "clientAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "ClientAccountNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "accountPtr",
                "storageKey": null,
                "args": null,
                "concreteType": "AccountNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "AccountProfile_account",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateClientAccountMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateClientAccount",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateClientAccount",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "clientAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "ClientAccountNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "accountPtr",
                "storageKey": null,
                "args": null,
                "concreteType": "AccountNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "actualCashBase",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "availableCashBase",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "stockValueBase",
                    "args": null,
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "actualCashBalances",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MultiCurrencyNode",
                    "plural": true,
                    "selections": (v5/*: any*/)
                  },
                  (v7/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "availableCashBalances",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MultiCurrencyNode",
                    "plural": true,
                    "selections": (v5/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "holdings",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "HoldingBalanceNode",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "portfolio",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PortfolioNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "trackingVue",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "VueNode",
                            "plural": false,
                            "selections": (v8/*: any*/)
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "contract",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "symbol",
                            "args": null,
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "quantity",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "brokerageCredits",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BrokerageCreditNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerageCreditNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BrokerageCreditNode",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "initialAmount",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "remainingAmount",
                                "args": null,
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "clientaccount",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClientAccountNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "accountStatus",
                        "args": null,
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "accruesInterest",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "feeTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeeTemplateNode",
                        "plural": false,
                        "selections": (v11/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "accountPtr",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AccountNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v7/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "adviser",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AdviserNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "parentTenant",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TenantNode",
                            "plural": false,
                            "selections": (v8/*: any*/)
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tenant",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TenantNode",
                        "plural": false,
                        "selections": (v11/*: any*/)
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "referenceNumber",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "userModels",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserModelNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserModelNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserModelNode",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v15/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  (v14/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "activeEmailRecipients",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserModelNode",
                        "plural": true,
                        "selections": (v6/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tradingApplication",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TradingApplicationNode",
                        "plural": false,
                        "selections": (v6/*: any*/)
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "bankAccounts",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BankAccountNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BankAccountNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BankAccountNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "holderName",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "bsbNumber",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "accountNumber",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "status",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "dividendPayout",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "tenantaccount",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TenantAccountNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tenant",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TenantNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/)
                        ]
                      },
                      (v2/*: any*/)
                    ]
                  }
                ]
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateClientAccountMutation",
    "id": null,
    "text": "mutation UpdateClientAccountMutation(\n  $clientAccountId: ID!\n  $name: String!\n) {\n  updateClientAccount(clientAccountId: $clientAccountId, name: $name) {\n    clientAccount {\n      accountPtr {\n        ...AccountProfile_account\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment AccountProfile_account on AccountNode {\n  id\n  name\n  actualCashBase\n  availableCashBase\n  stockValueBase\n  updatedAt\n  actualCashBalances {\n    currency\n    balance\n  }\n  notes {\n    id\n  }\n  availableCashBalances {\n    currency\n    balance\n  }\n  holdings {\n    portfolio {\n      id\n      trackingVue {\n        name\n        id\n      }\n    }\n    contract {\n      id\n      symbol\n      name\n    }\n    quantity\n    id\n  }\n  brokerageCredits {\n    edges {\n      node {\n        initialAmount\n        remainingAmount\n        id\n      }\n    }\n  }\n  clientaccount {\n    id\n    name\n    accountStatus\n    accountType\n    createdAt\n    updatedAt\n    accruesInterest\n    feeTemplate {\n      id\n      name\n    }\n    accountPtr {\n      id\n      notes {\n        id\n      }\n    }\n    adviser {\n      id\n      email\n      firstName\n      lastName\n      parentTenant {\n        name\n        id\n      }\n    }\n    tenant {\n      id\n      name\n    }\n    referenceNumber\n    userModels {\n      edges {\n        node {\n          id\n          username\n          email\n          firstName\n          lastName\n        }\n      }\n    }\n    activeEmailRecipients {\n      id\n    }\n    tradingApplication {\n      id\n    }\n  }\n  bankAccounts {\n    edges {\n      node {\n        id\n        holderName\n        bsbNumber\n        accountNumber\n        status\n        dividendPayout\n      }\n    }\n  }\n  tenantaccount {\n    name\n    accountType\n    createdAt\n    updatedAt\n    tenant {\n      id\n      name\n      email\n      firstName\n      lastName\n      username\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '05e8303507dac6cd866d6c603bd80061';
module.exports = node;
