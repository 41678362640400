import { graphql } from 'react-relay';

// language=GraphQL
const setAdviserMutation = graphql`
  mutation SetAdviserMutation(
    $clientAccountId: ID!
    $tenantId: ID!
    $adviserId: ID
  ) {
    setAdviser(
      clientAccountId: $clientAccountId
      tenantId: $tenantId
      adviserId: $adviserId
    ) {
      clientAccount {
        id
        name
        tenant {
          id
        }
        adviser {
          id
          firstName
          lastName
        }
        accountPtr {
          ...AccountProfile_account
        }
      }
    }
  }
`;
export default setAdviserMutation;
