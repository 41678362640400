import React from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import { renderAddress } from '../../../common/helpers';
import TableButton from '../../common/buttons/TableButton';
import AddressModalDlgForm from '../../common/dialogs/AddressModalDlgForm';
import { useCommonStyles } from '../../common/Styles';
import { doCommitPromise } from '../../../common/commit';
import updateUserAddress from '../../../mutations/UpdateUserAddress';

export default function AddressModifyTableButtonDrawer(props) {
  const {
    userModel,
    addressType,
    fieldName,
    title,
  } = props;

  const classes = useCommonStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  return (
    <>
      <Dialog
        open={drawerOpen}
        aria-labelledby="form-dialog-title"
        className={classes.dialogSingleInput}
        maxWidth="sm"
        fullWidth
      >
        <AddressModalDlgForm
          fieldName={fieldName}
          title={title}
          defaultValue={userModel[addressType]}
          onChangePromise={newAddress => doCommitPromise(
            updateUserAddress,
            {
              userId: userModel.userPtr.id,
              [addressType]: newAddress,
            },
            () => setDrawerOpen(false),
            () => null,
          )}
          onClose={() => setDrawerOpen(false)}
        />
      </Dialog>

      <Grid container spacing={1} justify="flex-end">
        <Grid item>
          {renderAddress(userModel[addressType])}
        </Grid>
        <Grid item>
          <TableButton
            color="primary"
            onClick={() => setDrawerOpen(true)}
          >
            Change
          </TableButton>
        </Grid>
      </Grid>
    </>
  );
}
