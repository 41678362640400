import * as React from 'react';
import EntitySelectionDlg from './EntitySelectionDlg';
import AdviserSearch from './AdviserSearch';

function AdviserSelectionDlg(props) {
  const {
    children,
    ...otherProps
  } = props;
  return (
    <EntitySelectionDlg
      {...otherProps}
      searchComp={AdviserSearch}
      dialogTitle="Select Adviser"
    />
  );
}

AdviserSelectionDlg.propTypes = {
  ...EntitySelectionDlg.propTypes,
};

AdviserSelectionDlg.defaultProps = {
  ...EntitySelectionDlg.defaultProps,
};

export default AdviserSelectionDlg;
