import { graphql } from 'react-relay';

const convertClientToAdviser = graphql`
  mutation ConvertClientToAdviserMutation (
    $userModelId: ID!
    $tenantId: ID
  ) {
    convertClientToAdviser (
      userModelId: $userModelId
      tenantId: $tenantId
    ) {
      adviser {
        id
        username
        email
        parentTenant {
          id
          name
        }
      }
      deactivatedUserModel {
        id
        username
      }
    }
  }
`;


export default convertClientToAdviser;
