import { toGlobalId } from '../../helpers/ID';
import { withTabItems } from '../common/RoutableTabContainer';
import AccountProfile from './AccountProfile';
import FxOrderList from '../order/FxOrderList';
import AccountActivityList from './CashActivityList';
import PortfolioOrderList from '../order/PortfolioOrderList';
import CashTransferMatchPane from '../transfer/CashTransferMatchPane';
import BrokerageCreditList from './BrokerageCreditList';
import ReportList from './ReportList';
import AccountStockHoldingList from './AccountStockHoldingList';
import AccountPermissions from './AccountPermissions';
import OffMarketTransferList from '../transfer/OffMarketTransferList';
import StockOrderList from '../order/StockOrderList';
import PositionTransferMatchPane from '../transfer/PositionTransferMatchPane';
import ChargesList from '../charge/ChargesList';
import OrderApprovalList from '../order/OrderApprovalList';
import CurrencyConversionRequestList from '../globals/CurrencyConversionRequestList';
import BulkOrderList from '../order/BulkOrderList';

const accountIdPass = props => ({
  accountId: toGlobalId('AccountNode', props.match.params.accountId),
  permissionEdges: props.permissionEdges,
});

const clientAccountIdPass = props => ({
  clientAccountId: toGlobalId('ClientAccountNode', props.match.params.accountId),
});

export default withTabItems(
  [
    {
      label: 'Profile',
      url: 'profile',
      comp: AccountProfile,
      propCopyFn: accountIdPass,
    },
    {
      label: 'Cash Transfers',
      url: 'cash-transfers',
      comp: CashTransferMatchPane,
      propCopyFn: accountIdPass,
    },
    {
      label: 'Position Transfers',
      url: 'position-transfers',
      comp: PositionTransferMatchPane,
      propCopyFn: accountIdPass,
    },
    {
      label: 'Vue Orders',
      url: 'vue-orders',
      comp: PortfolioOrderList,
      propCopyFn: accountIdPass,
    },
    {
      label: 'Stock Orders',
      url: 'stock-orders',
      comp: StockOrderList,
      propCopyFn: accountIdPass,
    },
    {
      label: 'Stock Holdings',
      url: 'stock-holdings',
      comp: AccountStockHoldingList,
      propCopyFn: accountIdPass,
    },
    {
      label: 'Charges',
      url: 'charges',
      comp: ChargesList,
      propCopyFn: accountIdPass,
    },
    {
      label: 'Approvals',
      url: 'approvals',
      comp: OrderApprovalList,
      propCopyFn: accountIdPass,
    },
    {
      label: 'Fx Requests',
      url: 'fx-requests',
      comp: CurrencyConversionRequestList,
      propCopyFn: accountIdPass,
    },
    {
      label: 'Fx Orders',
      url: 'fx-orders',
      comp: FxOrderList,
      propCopyFn: accountIdPass,
    },
    {
      label: 'Bulk Orders',
      url: 'bulk-orders',
      comp: BulkOrderList,
      propCopyFn: accountIdPass,
    },
    {
      label: 'Activity',
      url: 'activity',
      comp: AccountActivityList,
      propCopyFn: accountIdPass,
    },
    {
      label: 'Brokerage Credits',
      url: 'brokerage-credits',
      comp: BrokerageCreditList,
      propCopyFn: accountIdPass,
    },
    {
      label: 'Permission',
      url: 'permission',
      comp: AccountPermissions,
      propCopyFn: accountIdPass,
    },
    {
      label: 'Off Market Transfer',
      url: 'off-market-transfer',
      comp: OffMarketTransferList,
      propCopyFn: accountIdPass,
    },
    {
      label: 'EOFY-Reports',
      url: 'eofy-reports',
      comp: ReportList,
      propCopyFn: props => ({
        triggerIn: ['EOF'],
        freezeTrigger: true,
        optColumns: [
          {
            Header: 'Name',
            accessor: node => (node.customreport ? node.customreport.name : ''),
          },
          {
            Header: 'Year',
            accessor: node => (node.customreport ? node.customreport.year : ''),
          },
        ],
        showUpload: true,
        ...clientAccountIdPass(props),
      }),
    },
    {
      label: 'All-Reports',
      url: 'all-reports',
      comp: ReportList,
      propCopyFn: clientAccountIdPass,
    },
  ],
);
