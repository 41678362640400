import { graphql } from 'react-relay';

// language=GraphQL
const setAdminPermission = graphql`
  mutation SetAdminPermissionMutation(
    $userId: ID!
    $permissions: [AdminPermissionType]
  ) {
    setAdminPermission(
      userId: $userId
      permissions: $permissions
    ) {
      user {
        adminpermissionSet {
          edges {
            node {
              id
              verb
              resource
              updatedAt
            }
          }
        }
      }
    }
  }
`;

export default setAdminPermission;
